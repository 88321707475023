define("m-corp/routes/project/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aNkKClVu",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"fixed\"],[12],[2,\"\\n\"],[8,\"page-bg\",[],[[\"@image\",\"@banner\"],[\"bg-project@2x.png\",\"bg-project-banner@2x.png\"]],null],[2,\"\\n\"],[8,\"project-hero\",[],[[\"@project\"],[[32,0,[\"model\"]]]],null],[2,\"\\n\"],[8,\"project-details\",[],[[],[]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "m-corp/routes/project/template.hbs"
    }
  });

  _exports.default = _default;
});