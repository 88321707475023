define("m-corp/routes/components/about-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kCnpDNGm",
    "block": "{\"symbols\":[\"@project\",\"@section\",\"@isLazyLoading\"],\"statements\":[[2,\"\\n\"],[10,\"div\"],[15,0,[31,[\"relative flex flex-col\\n            min-w-\",[32,0,[\"cardMinWidth\"]],\"\\n            max-w-\",[32,0,[\"cardMaxWidth\"]],\"\\n            pointer-events-auto\\n            \",[32,1,[\"cardMargins\"]],\"\\n            project-card\\n            p-4\\n            mb-8\"]]],[15,1,[31,[[32,1,[\"id\"]]]]],[12],[2,\"\\n\\n\"],[6,[37,0],null,[[\"route\",\"model\"],[\"portfolio.project\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"io/project-card\",[],[[\"@project\",\"@width\",\"@height\",\"@classes\"],[[32,1],[32,0,[\"imageWidth\"]],[32,0,[\"imageHeight\"]],[31,[\"absolute\\n                -ml-4 -mt-4\\n                min-w-\",[32,0,[\"cardMinWidth\"]],\"\\n                max-w-\",[32,0,[\"cardMaxWidth\"]],\"\\n                w-full\\n                shadow-none\"]]]],null],[2,\"\\n\\n    \"],[10,\"div\"],[15,0,[31,[\"w-\",[32,0,[\"imageWidth\"]]]]],[15,5,[31,[\"--aspect-ratio:\",[32,0,[\"imageWidth\"]],\"/\",[32,0,[\"imageHeight\"]],\"; position:absolute\"]]],[12],[2,\"\\n\\n        \"],[8,\"portfolio-screen\",[],[[\"@project\",\"@opacity\",\"@width\",\"@height\"],[[32,1],\"0\",[32,0,[\"imageWidth\"]],[32,0,[\"imageHeight\"]]]],null],[2,\"\\n\\n    \"],[13],[2,\"\\n\\n\\n\\n    \"],[10,\"div\"],[14,0,\"relative\"],[15,5,[31,[\"--aspect-ratio:\",[32,0,[\"imageWidth\"]],\"/\",[32,0,[\"imageHeight\"]],\";\"]]],[12],[2,\"\\n      \"],[8,\"io/image-stack\",[],[[\"@project\",\"@section\",\"@isLazyLoading\"],[[32,1],[32,2],[32,3]]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n\\n\"],[2,\"    \"],[10,\"div\"],[14,0,\"flex w-full max-w-180\"],[12],[2,\"apple\"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"link-to\"]}",
    "meta": {
      "moduleName": "m-corp/routes/components/about-card/template.hbs"
    }
  });

  _exports.default = _default;
});