define("m-corp/routes/components/layout-double/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "80dNTL2S",
    "block": "{\"symbols\":[\"@project\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"relative z-30 flex flex-col\\n   mt-4\\n   mx-4\\n   md:mx-6\\n   md:my-6\\n   lg:mt-15 lg:mb-0\\n   lg:ml-39\\n   lg:mr-18\\n   xl:mx-39\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex\\n              md:mt-6\\n              lg:mt-5 max-w-page\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex-col w-2/5\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"detail-body mr-10\"],[12],[2,\"\\n        \"],[1,[32,1,[\"detailCopyFive\"]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"img\"],[14,0,\"w-full lg:w-auto mt-6 xl:mt-12 md:mb-17 lg:mb-25 mb-5 md:mb-0 \"],[14,\"alt\",\"pool\"],[15,\"src\",[31,[\"/assets/images/\",[32,1,[\"id\"]],\"/5@1x.jpg\"]]],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex w-3/5\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,0,\"w-full pb-6 pl-4 lg:pl-10 lg:w-auto lg:self-end\"],[14,\"alt\",\"kitchen\"],[15,\"src\",[31,[\"/assets/images/\",[32,1,[\"id\"]],\"/6@1x.jpg\"]]],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\\n\"],[18,2,null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "m-corp/routes/components/layout-double/template.hbs"
    }
  });

  _exports.default = _default;
});