define("m-corp/routes/components/cross-fade/component", ["exports", "@glimmer/component", "ember-animated/motions/opacity"], function (_exports, _component, _opacity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CrossFadeComponent extends _component.default {
    *focus({
      duration,
      insertedSprites,
      removedSprites,
      receivedSprites,
      sentSprites
    }) {
      //  console.log("Cross fade: ", arguments[0]);
      insertedSprites.forEach(sprite => {
        sprite.applyStyles({
          'z-index': '1'
        });
        (0, _opacity.fadeIn)(sprite, {
          duration: duration * 2
        });
      });
      removedSprites.forEach(sprite => {
        sprite.applyStyles({
          'z-index': '1'
        });
        (0, _opacity.fadeOut)(sprite, {
          duration: duration * 2
        });
      });
    }

  }

  _exports.default = CrossFadeComponent;
});