define("m-corp/routes/components/home-ms/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BJWIbm4r",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[30,[36,2],[[32,0,[\"router\",\"currentRouteName\"]],\"index\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,0,[\"appState\",\"didRender\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"div\"],[24,0,\"flex absolute top-0 h-screen items-center justify-center\"],[4,[38,0],[[32,0,[\"animateMs\"]]],null],[12],[2,\"\\n      \"],[10,\"img\"],[14,0,\"object-cover duration-500 px-20 xs-vrt:mt-10 sm-vrt:mt-10 md-vrt:mt-16\"],[14,\"src\",\"/assets/images/M@2x.png\"],[14,\"alt\",\"M\"],[12],[13],[2,\"\\n      \"],[10,\"img\"],[14,0,\"absolute -mt-24 z-100 duration-500 delay-2000\"],[14,\"src\",\"/assets/images/M-small@1x.png\"],[14,\"alt\",\"Millennium\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"unless\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "m-corp/routes/components/home-ms/template.hbs"
    }
  });

  _exports.default = _default;
});