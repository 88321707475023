define("m-corp/routes/one/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class OneRoute extends Ember.Route {}

  _exports.default = OneRoute;
});