define("m-corp/routes/portfolio/paris-map/controller", ["exports", "ember-animated/motions/opacity", "ember-animated/motions/move", "ember-animated/motions/resize", "d3-ease", "ember-animated/-private/sprite"], function (_exports, _opacity, _move, _resize, _d3Ease, _sprite) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /* global L */
  const inViewport = (e, {
    top: t,
    height: h
  } = e.getBoundingClientRect()) => t <= innerHeight && t + h >= 0;

  let PortfolioParisMapController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = (_temp = class PortfolioParisMapController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _defineProperty(this, "activeLocation", null);

      _defineProperty(this, "spriteId", 'sprite' + Ember.guidFor(this).substring(5));

      _initializerDefineProperty(this, "viewportManager", _descriptor, this);

      _initializerDefineProperty(this, "routeAnimation", _descriptor2, this);

      _initializerDefineProperty(this, "isInViewport", _descriptor3, this);

      _initializerDefineProperty(this, "viewportIndex", _descriptor4, this);

      this.transition = this.transition.bind(this);
    } // get incomingMatchId() {
    //   console.log('tracking AI updating incoming ', this.spriteId, ', ', this.viewportManager.lastViewportImages.objectAt(this.viewportIndex));
    //   return this.viewportManager.lastViewportImages.objectAt(this.viewportIndex);
    // }
    // get outgoingMatchId() {
    //   console.log('tracking AI updating outgoing ', this.spriteId, ', ', this.viewportManager.viewportImages.objectAt(this.viewportIndex));
    //   return this.viewportManager.viewportImages.objectAt(this.viewportIndex);
    // }


    didInsertBoo(element) {
      // console.log('tracking didInsert AI with id: ', this.spriteId)
      this.viewportManager.addDestinationPageElement(this.spriteId);

      if (inViewport(element)) {
        this.isInViewport = true;
        this.viewportManager.addElement(this.spriteId); //this.viewportIndex = this.viewportManager.indexOfIncomingSprite(this.spriteId);
        //this.dynamicAnimationId = this.viewportManager.animationIdForIncomingElement(this.spriteId);
        // later(this, function () {
        //   this.viewportManager.lastViewportImages.pushObject("apples");
        // }, 15000);
      }
    }

    inDoor() {
      this.viewportManager.addElement(this.spriteId);
      this.isInViewport = true;
    }

    outDoor() {
      this.viewportManager.removeElement(this.spriteId);
      this.isInViewport = false;
    }

    get goldMarker() {
      return L.icon({
        iconUrl: '/assets/images/marker-gold@2x.png',
        iconSize: [20, 35],
        iconAnchor: [12, 35],
        popupAnchor: [0, -25] //    shadowUrl: 'assets/images/marker-shadow.png',
        //   shadowSize: [35, 45],
        //  shadowAnchor: [9, 42]

      });
    }

    togglePopup(location) {
      this.activeLocation.isOpen = false;
      location.isOpen = !location.isOpen;
      this.activeLocation = location;
    }

    *transition({
      duration,
      keptSprites,
      sentSprites,
      receivedSprites,
      insertedSprites,
      removedSprites
    }) {
      //  console.log('route animation isDynamic: ', this.routeAnimation.isDynamic);
      insertedSprites.forEach(sprite => {
        let targetId;
        let target; // if(isPresent(this.args.forcedMatch) && !this.routeAnimation.isDynamic){
        //   target = document.getElementById('orphans').querySelector(`[data-match="${this.args.forcedMatch}"]`);
        // } else {

        targetId = this.viewportManager.proximityMatchFor(this.spriteId);
        target = document.getElementById(targetId); // }

        if (Ember.isPresent(target)) {
          let targetSprite = new _sprite.default(target, true);
          sprite.startAtSprite(targetSprite);
          sprite.applyStyles({
            'z-index': '200',
            'max-width': 'none'
          });
          (0, _resize.default)(sprite, {
            easing: _d3Ease.easeExpInOut
          });
          (0, _move.default)(sprite, {
            easing: _d3Ease.easeExpInOut
          });
          new _opacity.Opacity(sprite, {
            from: 0,
            to: 1,
            duration: duration * 1
          }).run();
        } else {
          if (this.routeAnimation.isDynamic) {
            if (sprite.absoluteFinalBounds.left < window.innerWidth - sprite.absoluteFinalBounds.right) {
              sprite.startAtPixel({
                x: -sprite.absoluteFinalBounds.width
              });
              (0, _move.default)(sprite, {
                easing: _d3Ease.easeExpInOut,
                duration: duration * 1
              });
              (0, _opacity.fadeIn)(sprite);
            } else {
              sprite.startAtPixel({
                x: window.innerWidth + 0
              });
              (0, _move.default)(sprite, {
                easing: _d3Ease.easeExpInOut,
                duration: duration * 1
              });
              (0, _opacity.fadeIn)(sprite);
            }
          } else {
            sprite.applyStyles({
              'z-index': 'unset'
            });
            (0, _opacity.fadeIn)(sprite);
          }
        }
      });

      for (let sprite of removedSprites) {
        let targetId;
        let target; // if(isPresent(this.args.forcedMatch) && !this.routeAnimation.isDynamic){
        //   target = document.querySelector(`[data-match="${this.args.forcedMatch}"]`);
        // } else {

        targetId = this.viewportManager.proximityMatchFor(this.spriteId);

        if (Ember.isPresent(targetId)) {
          target = document.getElementById(targetId); // }
        }

        if (Ember.isPresent(target)) {
          // console.log('match for for removed: ', this.spriteId, targetId);
          let targetSprite = new _sprite.default(target);
          sprite.endAtSprite(targetSprite);
          sprite.applyStyles({
            'z-index': '100',
            'max-width': 'none'
          });
          (0, _resize.default)(sprite, {
            easing: _d3Ease.easeExpInOut
          });
          (0, _move.default)(sprite, {
            easing: _d3Ease.easeExpInOut
          });
          (0, _opacity.fadeOut)(sprite);
        } else {
          if (this.routeAnimation.isDynamic) {
            // if the route transition is dynamic to one of these
            if (sprite.initialBounds.left < window.innerWidth - sprite.initialBounds.right) {
              sprite.endAtPixel({
                x: -sprite.initialBounds.width
              });
              (0, _move.default)(sprite, {
                easing: _d3Ease.easeExpInOut,
                duration: duration * 1
              });
              (0, _opacity.fadeOut)(sprite, {
                duration: duration * 0.35
              });
            } else {
              sprite.endAtPixel({
                x: window.innerWidth + 0
              });
              (0, _move.default)(sprite, {
                easing: _d3Ease.easeExpInOut,
                duration: duration * 1
              });
              (0, _opacity.fadeOut)(sprite, {
                duration: duration * 0.35
              });
            }
          } else {
            // if the route transition is not dynamic
            // leave these in place, fade to .25 and scale to the opposite of the hover
            sprite.applyStyles({
              'z-index': 'unset'
            });
            (0, _opacity.fadeOut)(sprite);
          }
        }
      }
    }
    /*
    *transition({duration, keptSprites, sentSprites, receivedSprites, insertedSprites, removedSprites}){
      // send from project
      for (let sprite of sentSprites) {
        if(sprite.element.dataset.role === 'placeholder'){
         // console.log('sending from project');
          sprite.applyStyles({
            'z-index': '50',
          });
          resize(sprite, {easing: easeExpInOut});
          move(sprite, {easing: easeExpInOut});
          if(sprite.finalComputedStyle.opacity > 0){
            fadeIn(sprite, {duration: duration / 1});
          } else {
            fadeOut(sprite, {duration: duration / 1});
          }
        }
        // the dataset.direciton was to tweak the animation so
        // it was always the project side that was animating the
        // send and receives. ie the target
        //
        // we shuould be able to monito the sourbe/target
        // using the appstate service {{aninmator.location (source/target)}}
        // orientaion position ani-id ani-role sprite-id sprite-role anim-id anim-role
        // sprite-id sprite-role, role animator/placeholer vs source/target
        //
        // if we can animate the sender out and receiver in
        // we can animate without caring about having the stack
        // on both ends
        //
        // so the @role needs to change from a static placeholder
        // to the originator of the image
        //
        // or
        //
        // we could set the inserted sprite to the same position of the removed sprite
        // and vice versa, givining them inserted initialBounds and removed finalBounds
        // of added and removed sprites.
        //
        // I like the idea using added and removed since that is what the are
        // viewportManager provides the index positioon of images
        // on willTransition dimension and position are updated
        // on did-insert (?) we share the dimension of the incoming
        //
        //
        // use transition objects to mark sender vs receiver
        // if this.route matches from.route then wer are the sender
         // essentially this letting us dynamivally setup sender and receiver
        //
        // j
      }
       // received to project
      for (let sprite of receivedSprites) {
        if(sprite.element.dataset.role === 'animator'){
          // console.log('receiving to project');
          sprite.applyStyles({
            'z-index': '50',
          });
          resize(sprite, {easing: easeExpInOut});
          move(sprite, {easing: easeExpInOut});
          if(sprite.finalComputedStyle.opacity > 0){
            fadeIn(sprite, {duration: duration / 1});
          } else {
            fadeOut(sprite, {duration: duration / 1});
          }
        }
      }
       //  yield wait(2000);
      insertedSprites.forEach(sprite => {
        let targetId = this.incomingMatchId;
        let target = document.getElementById(targetId);
        if(isPresent(target)){
          let targetSprite = new Sprite(target);
          let diff = sprite.difference('finalBounds', targetSprite, 'finalBounds');
          sprite.startTranslatedBy(-diff.dx, -diff.dy);
          sprite._initialBounds = new DOMRect(
            sprite._initialBounds.x,
            sprite._initialBounds.y,
            targetSprite.finalBounds.width,
            targetSprite.finalBounds.height,
          );
          sprite._initialComputedStyle = targetSprite.finalComputedStyle;
          sprite._initialCumulativeTransform = targetSprite.finalCumulativeTransform;
           sprite.applyStyles({ 'z-index': '100' });
          resize(sprite, { easing: easeExpInOut });
          move(sprite, { easing: easeExpInOut });
          new Opacity(sprite, { from: 0, to: 1, duration : duration * 1}).run();
         }
      });
       // console.log('transition tracking last viewportImages ', this.viewportManager.lastViewportImages);
      // console.log('transition tracking viewportImages ', this.viewportManager.viewportImages);
      for (let sprite of removedSprites) {
        let targetId = this.outgoingMatchId;
        let target = document.getElementById(targetId);
        if(isPresent(target)){
          let targetSprite = new Sprite(target);
          let diff = targetSprite.difference('finalBounds', sprite, 'initialBounds');
          sprite.endAtSprite(targetSprite);
          resize(sprite, {easing: easeExpInOut});
          move(sprite, {easing: easeExpInOut});
          fadeOut(sprite);
        }
      }
     }
    */

    /*
    *transition({ insertedSprites, receivedSprites, sentSprites, removedSprites }) {
       insertedSprites.forEach(sprite => {
         sprite.applyStyles({
          'z-index': '200'
        });
        fadeIn(sprite);
      });
       receivedSprites.forEach(sprite => {
        sprite.applyStyles({
          'z-index': '20'
        });
        resize(sprite, {easing: easeExpInOut});
        move(sprite, {easing: easeExpInOut});
        fadeIn(sprite, {easing: easeExpInOut});
      });
       sentSprites.forEach(sprite => {
        sprite.applyStyles({
          'z-index': '20'
        });
        resize(sprite, {easing: easeExpInOut});
        move(sprite, {easing: easeExpInOut});
        fadeOut(sprite, {easing: easeExpInOut});
      });
     }
    */


  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "viewportManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "routeAnimation", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isInViewport", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "viewportIndex", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "didInsertBoo", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "didInsertBoo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "inDoor", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "inDoor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "outDoor", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "outDoor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "togglePopup", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "togglePopup"), _class.prototype)), _class));
  _exports.default = PortfolioParisMapController;
});