define("m-corp/services/app-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AppStateService = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, (_class = (_temp = class AppStateService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "didRender", _descriptor, this);

      _defineProperty(this, "portfolioSection", void 0);

      _initializerDefineProperty(this, "parentPortfolioRoute", _descriptor2, this);

      _initializerDefineProperty(this, "sectionTitleVisibility", _descriptor3, this);

      _initializerDefineProperty(this, "currentRouteModel", _descriptor4, this);

      _initializerDefineProperty(this, "contentHeight", _descriptor5, this);

      _initializerDefineProperty(this, "portfolioMenuItems", _descriptor6, this);

      _defineProperty(this, "projectCategories", Ember.A([{
        index: '0',
        name: 'Featured'
      }, {
        index: '1',
        name: 'Master-planned'
      }, {
        index: '2',
        name: 'Hospitality'
      }, {
        index: '3',
        name: 'Commercial'
      }, {
        index: '4',
        name: 'Residential Rental'
      }, {
        index: '5',
        name: 'Restoration'
      }, {
        index: '6',
        name: 'Paris, France'
      }, {
        index: '7',
        name: 'All'
      }]));
    }

    get sectionTitle() {
      if (Ember.isPresent(this.portfolioSection)) {
        return this.portfolioSection;
      } else {
        let defaultCategory = this.currentRouteModel.projectCategories.objectAt(0);
        let defaultSection = this.projectCategories.objectAt(defaultCategory);

        if (defaultSection.name === 'Master-planned') {
          return 'master-<br>planned';
        } else if (defaultSection.name === 'Residential Rental') {
          return 'residential<br>rentail';
        }

        return defaultSection.name;
      }
    }

    hideSectionTitle() {
      this.sectionTitleVisibility = false;
    }

    showSectionTitle() {
      this.sectionTitleVisibility = true;
    }

    setupMenuForProject(model) {
      this.currentRouteModel = model;
      this.adjustPortfolioMenu();
    }

    emptyPortfolioMenu() {
      this.portfolioMenuItems = Ember.A([]);
    }

    fillPortfolioMenu() {
      this.portfolioMenuItems.addObjects(this.projectCategories);
    }

    adjustPortfolioMenu() {
      let remainingItems = this.currentRouteModel.projectCategories;

      if (Ember.isEmpty(this.portfolioMenuItems)) {
        for (let i = 0; i < remainingItems.length; i++) {
          this.portfolioMenuItems.addObject(this.projectCategories.objectAt(i));
        }
      } else {
        for (let i = this.projectCategories.length - 1; i >= 0; i--) {
          // console.log(i);
          if (!remainingItems.includes(i)) {
            this.portfolioMenuItems.removeAt(i);
          }
        }
      }
    }

    resetPortfolioMenu() {
      // console.log('adding sitemenu');
      for (let category of this.projectCategories) {
        if (this.portfolioMenuItems.findBy('name', category.name) === undefined) {
          this.portfolioMenuItems.insertAt(category.index, category);
        }
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "didRender", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "parentPortfolioRoute", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "sectionTitleVisibility", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentRouteModel", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "contentHeight", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "portfolioMenuItems", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([{
        index: '0',
        name: 'Featured'
      }, {
        index: '1',
        name: 'Master-planned'
      }, {
        index: '2',
        name: 'Hospitality'
      }, {
        index: '3',
        name: 'Commercial'
      }, {
        index: '4',
        name: 'Residential Rental'
      }, {
        index: '5',
        name: 'Restoration'
      }, {
        index: '6',
        name: 'Paris, France'
      }, {
        index: '7',
        name: 'All'
      }]);
    }
  })), _class));
  _exports.default = AppStateService;
});