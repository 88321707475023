define("m-corp/helpers/is-nav-active", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IsNavActive extends Helper {
    compute([item]) {
      return params;
    }

  }

  _exports.default = IsNavActive;
});