define("m-corp/routes/components/io/image-group/component", ["exports", "@glimmer/component", "ember-animated/motions/opacity", "d3-ease", "ember-animated/motions/move", "ember-animated/motions/resize"], function (_exports, _component, _opacity, _d3Ease, _move, _resize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IoImageGroupComponent extends _component.default {
    get orientation() {
      if (this.args.isSource) {
        return 'source';
      } else {
        return 'target';
      }
    }

    *transition({
      duration,
      keptSprites,
      sentSprites,
      receivedSprites,
      insertedSprites,
      removedSprites
    }) {
      // send from project
      for (let sprite of sentSprites) {
        if (sprite.element.dataset.orientation === 'target') {
          // console.log('sending from project');
          sprite.applyStyles({
            'z-index': '50'
          });
          (0, _resize.default)(sprite, {
            easing: _d3Ease.easeExpInOut
          });
          (0, _move.default)(sprite, {
            easing: _d3Ease.easeExpInOut
          });

          if (sprite.finalComputedStyle.opacity > 0) {
            (0, _opacity.fadeIn)(sprite, {
              duration: duration / 1
            });
          } else {
            (0, _opacity.fadeOut)(sprite, {
              duration: duration / 1
            });
          }
        } // the dataset.direciton was to tweak the animation so
        // it was always the project side that was animating the
        // send and receives. ie the target
        //
        // we shuould be able to monito the sourbe/target
        // using the appstate service {{aninmator.location (source/target)}}
        // orientaion position ani-id ani-role sprite-id sprite-role anim-id anim-role
        // sprite-id sprite-role, role animator/placeholer vs source/target

      } // received to project


      for (let sprite of receivedSprites) {
        if (sprite.element.dataset.orientation === 'target') {
          // console.log('receiving to project');
          sprite.applyStyles({
            'z-index': '50'
          });
          (0, _resize.default)(sprite, {
            easing: _d3Ease.easeExpInOut
          });
          (0, _move.default)(sprite, {
            easing: _d3Ease.easeExpInOut
          });

          if (sprite.finalComputedStyle.opacity > 0) {
            (0, _opacity.fadeIn)(sprite, {
              duration: duration / 1
            });
          } else {
            (0, _opacity.fadeOut)(sprite, {
              duration: duration / 1
            });
          }
        }
      }

      insertedSprites.forEach(sprite => {
        sprite.applyStyles({
          'z-index': '1'
        });
        sprite.startAtPixel({
          x: window.innerWidth + 200
        });
        (0, _move.default)(sprite, {
          easing: _d3Ease.easeExpInOut
        });
      });
      removedSprites.forEach(sprite => {
        sprite.applyStyles({
          'z-index': '1'
        });
        sprite.endAtPixel({
          x: window.innerWidth + 200
        });
        (0, _move.default)(sprite, {
          easing: _d3Ease.easeExpInOut,
          duration: duration * 1
        });
      });
    }

  }

  _exports.default = IoImageGroupComponent;
});