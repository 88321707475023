define("m-corp/routes/components/portfolio-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V3qpKNpy",
    "block": "{\"symbols\":[\"value\",\"@project\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"flex flex-col \",[32,2,[\"cardMargins\"]],\"\\n            w-px-\",[32,2,[\"cardWidth\"]],\"\\n            h-px-\",[32,2,[\"cardHeight\"]],\"\\n            p-4 hover:bg-white\\n            shadow-none\\n            hover:xxborder-2\\n            hover:xxborder-orange-500\\n            hover:shadow-2xl\"]]],[14,5,\"transition: 0.35s\"],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"w-px-\",[32,2,[\"imageWidth\"]],\"\\n              h-px-\",[32,2,[\"imageHeight\"]]]]],[12],[2,\"\\n  \"],[8,\"animated-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,0,[\"isBlurred\"]]],[[\"initialInsertion\",\"use\"],[true,[32,0,[\"transition\"]]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"w-px-\",[32,2,[\"imageWidth\"]],\"\\n              h-px-\",[32,2,[\"imageHeight\"]],\"\\n              bg-gray-100\"]]],[12],[2,\"\\n\"],[2,\"    \"],[11,\"button\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"focus\"]],[32,2]],null]],null],[12],[2,\"\\n    \"],[10,\"img\"],[15,\"xd-class\",[31,[\"w-px-\",[32,2,[\"imageWidth\"]],\"\\n             h-px-\",[32,2,[\"imageHeight\"]]]]],[15,\"alt\",[32,2,[\"name\"]]],[15,\"src\",[32,2,[\"portfolioImage\"]]],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"text-center p-8\"],[12],[2,\"\\n    \"],[1,[32,2,[\"type\"]]],[2,\" | \"],[1,[32,2,[\"location\"]]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"\"],[12],[2,\"\\n      \"],[1,[32,2,[\"name\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"\"],[12],[2,\"\\n      \"],[1,[32,2,[\"description\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"animated-if\"]}",
    "meta": {
      "moduleName": "m-corp/routes/components/portfolio-item/template.hbs"
    }
  });

  _exports.default = _default;
});