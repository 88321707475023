define("m-corp/routes/components/io/staged-card/component", ["exports", "@glimmer/component", "ember-animated/motions/move", "d3-ease", "ember-animated/motions/resize"], function (_exports, _component, _move, _d3Ease, _resize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let IoStagedCardComponent = (_dec = Ember.inject.service, (_class = (_temp = class IoStagedCardComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);
    }

    //@action
    // matchPageHeight(element){
    //   // let height = document.querySelector(".projectPageHeight").offsetHeight;
    //   let height = document.documentElement.offsetHeight;
    //   element.style.height = `${height}px`;
    // }
    //
    get backgroundImage() {
      let filename = 'generic-bg@2x.png';

      if (this.args.project.id === "etoile") {
        filename = 'etoile-bg@2x.png';
      }

      if (this.args.project.id === 'triomphe') {
        filename = 'triomphe-bg@2x.png';
      }

      if (this.args.project === 'city-in-the-park') {
        filename = 'city-in-the-park-bg@2x.png';
      }

      return `url("/assets/images/backgrounds/${filename}")`;
    }

    *cardTransition({
      receivedSprites,
      sentSprites
    }) {
      // console.log("StagedCard: ", arguments[0]);
      receivedSprites.forEach(sprite => {
        //console.log('ProjectCard animating received');
        (0, _move.default)(sprite, {
          easing: _d3Ease.easeExpInOut
        });
        (0, _resize.default)(sprite, {
          easing: _d3Ease.easeExpInOut
        });
      });
      sentSprites.forEach(sprite => {
        sprite.applyStyles({
          'z-index': '10'
        });
        (0, _move.default)(sprite, {
          easing: _d3Ease.easeExpInOut
        });
        (0, _resize.default)(sprite, {
          easing: _d3Ease.easeExpInOut
        });
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = IoStagedCardComponent;
});