define("m-corp/routes/components/layout-boheme/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yj13p+AO",
    "block": "{\"symbols\":[\"@project\"],\"statements\":[[8,\"layout-default\",[],[[\"@project\"],[[32,1]]],null],[2,\"\\n\"],[8,\"layout-landscape\",[],[[\"@margin\",\"@project\"],[false,[32,1]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "m-corp/routes/components/layout-boheme/template.hbs"
    }
  });

  _exports.default = _default;
});