define("m-corp/mirage/fixtures/paris", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 'east-side',
    name: 'East Side',
    description: 'description',
    location: 'Paris, France',
    address: '2 Boulevard Newton, 77420 Champs-sur-Marne',
    type: 'RESIDENTIAL',
    lat: '48.8425017',
    lng: '2.5805362',
    sort: 1,
    isOpen: true
  }, {
    id: 'bellevue-park',
    name: 'Bellevue Park',
    description: 'description',
    location: 'Paris, France',
    address: '16-18, Ave de la Division Leclerc',
    type: 'RESIDENTIAL',
    lat: '48.8658158',
    lng: '2.1820995',
    sort: 2
  }, {
    id: 'st-georges-residences',
    name: 'St. Georges<br>Residences',
    description: 'description',
    location: 'Paris, France',
    address: '7 Rue du Général de Gaulle',
    type: 'RESIDENTIAL',
    lat: '48.8366876',
    lng: '2.519812',
    sort: 3
  }, {
    id: 'les-jardin-de-bellevue',
    name: 'Les Jardin<br>de Bellevue',
    description: 'description',
    location: 'Paris, France',
    address: '83-85 Avenue de Verdun',
    type: 'RESIDENTIAL',
    lat: '48.7998093',
    lng: '2.2124568',
    sort: 4
  }, {
    id: 'le-saint-exupery',
    name: 'Le Saint-Exupery',
    description: 'description',
    location: 'Paris, France',
    address: '4 Avenue Saint-Exupéry',
    type: 'RESIDENTIAL',
    lat: '48.8088685',
    lng: '2.2638004',
    sort: 5
  }, {
    id: 'les-terrasses-du-parc',
    name: 'Les Terrasses<br>du Parc',
    description: 'description',
    location: 'Paris, France',
    address: '5 Avenue de Denton',
    type: 'RESIDENTIAL',
    lat: '48.7900784',
    lng: '1.9723422',
    sort: 6
  }, {
    id: 'le-rablais',
    name: 'Le Rablais',
    description: 'description',
    location: 'Antony, France',
    address: '13-23 Avenue de la Division Leclerc',
    type: 'RESIDENTIAL',
    lat: '48.7526364',
    lng: '2.3027093',
    sort: 7
  }, {
    id: 'le-carre-de-seine',
    name: 'Le Carre de Seine',
    description: 'description',
    location: 'Paris, France',
    address: 'Rue de Seine',
    type: 'RESIDENTIAL',
    lat: '48.8545216',
    lng: '2.3325257',
    sort: 8
  }, {
    id: 'les-Rives-d-snieres',
    name: 'Les Rives<br>d`Asnieres',
    description: 'description',
    location: 'Asnieres-sur-Seine, France',
    address: '2 Rue Marceau Delorme',
    type: 'RESIDENTIAL',
    lat: '48.9108171',
    lng: '2.278976',
    sort: 9
  }, {
    id: 'italie-avenue',
    name: 'Italie Avenue',
    description: 'description',
    location: 'Paris, France',
    address: '97 Avenue d`Italie',
    type: 'RESIDENTIAL',
    lat: '48.8233625',
    lng: '2.3410582',
    sort: 10
  }, {
    id: 'les-terasses-parisiennes',
    name: 'Les Terasses<br>Parisiennes',
    description: 'description',
    location: 'Montrouge, France',
    address: 'Rue d`Estienne d`Orves',
    type: 'RESIDENTIAL',
    lat: '48.8149977',
    lng: '2.318545',
    sort: 11
  }, {
    id: 'le-jardin-des-poetes',
    name: 'Le Jardin<br>des Poetes',
    description: 'description',
    location: 'Saint-Denis, France',
    address: 'Rue Elsa Triolet & Rue Paul Eluard',
    type: 'RESIDENTIAL',
    lat: '48.939147',
    lng: '2.3449771',
    sort: 12
  }, {
    id: 'les-terrasses-kennedy',
    name: 'Les Terrasses<br>Kennedy',
    description: 'description',
    location: 'Antony, France',
    address: '30-32 Avenue du President Kennedy',
    type: 'RESIDENTIAL',
    lat: '48.7397028',
    lng: '2.2981711',
    sort: 13
  }, {
    id: 'les-jardin-marguerite',
    name: 'Les Jardin<br>Marguerite',
    description: 'description',
    location: 'Saint-Denis, France',
    address: '3 Rue Auguste Blanqui',
    type: 'RESIDENTIAL',
    lat: '48.9378426',
    lng: '2.35481',
    sort: 14
  }, {
    id: 'villa-montaigne',
    name: 'Villa Montaigne',
    description: 'description',
    location: 'Antony, France',
    address: '126 Avenue de la Division Leclerc',
    type: 'RESIDENTIAL',
    lat: '48.7451331',
    lng: '2.3009141',
    sort: 15
  }, {
    id: 'les-jardins-chagall',
    name: 'Les Jardins<br>Chagall',
    description: 'description',
    location: 'Boulogne-Billancourt, France',
    address: '127 Rue du Vieux Pont de Sèvres',
    type: 'RESIDENTIAL',
    lat: '48.8325925',
    lng: '2.2430788',
    sort: 16
  }, {
    id: 'le-clos-des-vignes',
    name: 'Le Clos<br>des Vignes',
    description: 'description',
    location: 'Taverny, France',
    address: '7-9, rue de Paris/5, rue des Pareux',
    type: 'RESIDENTIAL',
    lat: '49.0179113',
    lng: '2.2441131',
    sort: 17
  }, {
    id: 'Le-Dominial-de-Saint-Witz',
    name: 'Le Dominial<br>de Saint-Witz',
    description: 'description',
    location: 'Saint-Witz, France',
    address: '1 Rue Charles Péguy',
    type: 'RESIDENTIAL',
    lat: '49.091911',
    lng: '2.5753743',
    sort: 18
  }, {
    id: 'st-james-park',
    name: 'St. James Park',
    description: 'description',
    location: 'Paris, France',
    address: 'Châtillon - Montrouge Metro',
    type: 'RESIDENTIAL',
    lat: '48.8110284',
    lng: '2.3002975',
    sort: 19
  }, {
    id: 'regent-park',
    name: 'Regent Park',
    description: 'description',
    location: 'Guyancourt, France',
    address: '21 Rue Jules Michelet',
    type: 'RESIDENTIAL',
    lat: '48.7657024',
    lng: '2.0725321',
    sort: 20
  }, {
    id: 'le-dome',
    name: 'Le Dome',
    description: 'description',
    location: 'Cergy, France',
    address: '1-3 Rue des Brumes Lactees',
    type: 'RESIDENTIAL',
    lat: '49.050123',
    lng: '2.0104512',
    sort: 21
  }, {
    id: 'villa-du-theatre',
    name: 'Villa du Theatre',
    description: 'description',
    location: 'Châtillon, France',
    address: '13 Avenue de la Division Leclerc',
    type: 'RESIDENTIAL',
    lat: '48.8715143',
    lng: '2.2612151',
    sort: 22
  }, {
    id: 'le-rabelais',
    name: 'Le Rabelais',
    description: 'description',
    location: 'Boulogne-Billancourt, France',
    address: '5 Rue de Chateaudun',
    type: 'RESIDENTIAL',
    lat: '48.8388846',
    lng: '2.2328997',
    sort: 23
  }, {
    id: 'les-jardin-d’ormesson',
    name: 'Les Jardin<br>d’Ormesson',
    description: 'description',
    location: 'Paris, France',
    address: '85 Rue de la Baume',
    type: 'RESIDENTIAL',
    lat: '48.8749562',
    lng: '2.3118253',
    sort: 24
  }, {
    id: 'le-coeur-de-ville',
    name: 'Le Coeur<br>de Ville',
    description: 'description',
    location: 'Belfort, France',
    address: '23 Rue du Général Strolz 21',
    type: 'RESIDENTIAL',
    lat: '47.6387179',
    lng: '6.853969',
    sort: 25
  }];
  _exports.default = _default;
});