define("m-corp/helpers/unless-project-ids-match", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function unlessProjectIdsMatch([projectId, animatingFromProjectId]) {
    if (animatingFromProjectId === undefined) {
      return true;
    } else if (projectId === animatingFromProjectId) {
      return false;
    } else {
      return true;
    }
  });

  _exports.default = _default;
});