define("m-corp/routes/care/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CareRoute extends Ember.Route {
    model() {
      return Ember.RSVP.hash({
        user: {}
      });
    }

  }

  _exports.default = CareRoute;
});