define("m-corp/routes/components/layout-province/component", ["exports", "@glimmer/component", "ember-animated/motions/opacity", "ember-animated"], function (_exports, _component, _opacity, _emberAnimated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LayoutProvinceComponent extends _component.default {
    *transition({
      duration,
      insertedSprites,
      removedSprites
    }) {
      // console.log("project details: ", arguments[0]);
      removedSprites.forEach(sprite => {
        // sprite.applyStyles({
        //   'z-index': '100'
        // });
        (0, _opacity.fadeOut)(sprite, {
          duration: duration / 8
        });
      });
      yield (0, _emberAnimated.wait)(duration / 2);
      insertedSprites.forEach(sprite => {
        // sprite.applyStyles({
        //   'z-index': '100'
        // });
        (0, _opacity.fadeIn)(sprite, {
          duration: duration / 2
        });
      });
    }

  }

  _exports.default = LayoutProvinceComponent;
});