define("m-corp/routes/components/filler-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VvRpXl6r",
    "block": "{\"symbols\":[\"@project\"],\"statements\":[[2,\"\\n\"],[10,\"div\"],[15,0,[31,[\"relative flex flex-col\\n            w-1/2 md:w-full\\n            min-w-\",[32,0,[\"cardMinWidth\"]],\"\\n            max-w-\",[32,0,[\"cardMaxWidth\"]],\"\\n            pointer-events-auto\\n            \",[32,1,[\"cardMargins\"]],\"\\n            p-2\\n            md:p-4 md:mb-8\"]]],[12],[2,\"\\n\\n\"],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"relative\"],[15,5,[31,[\"--aspect-ratio:\",[32,0,[\"imageWidth\"]],\"/\",[32,0,[\"imageHeight\"]],\";\"]]],[12],[2,\"\\n\"],[2,\"      \"],[8,\"io/animated-image\",[],[[\"@alt\",\"@forcedMatch\",\"@src\"],[\"Message Stuff\",[30,[36,0],[\"static\",[32,1,[\"id\"]]],null],[32,0,[\"fillerImage\"]]]],null],[2,\"\\n      \"],[11,\"div\"],[24,0,\"absolute z-20 top-0 object-cover w-full h-full opacity-0 shadow-inner-md\"],[4,[38,1],null,[[\"enterClass\",\"enterActiveClass\",\"enterToClass\",\"leaveClass\",\"leaveActiveClass\",\"leaveToClass\"],[\"opacity-0\",\"transition-all duration-1000 delay-1000\",\"opacity-100\",\"opacity-100\",\"transition-all duration-1000 delay-1000\",\"opacity-0\"]]],[12],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"css-transition\"]}",
    "meta": {
      "moduleName": "m-corp/routes/components/filler-card/template.hbs"
    }
  });

  _exports.default = _default;
});