define("m-corp/routes/components/io/staged-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zlwk+U9X",
    "block": "{\"symbols\":[\"@classes\",\"@project\"],\"statements\":[[8,\"animated-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,2,[\"id\"]],\"card\"],null]],[[\"initialInsertion\",\"finalRemoval\",\"duration\",\"use\"],[true,true,1000,[32,0,[\"cardTransition\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[11,\"div\"],[16,0,[32,1]],[4,[38,0],null,[[\"background-image\",\"background-size\"],[[32,0,[\"backgroundImage\"]],\"cover\"]]],[12],[2,\"\\n     \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"style\",\"concat\",\"animated-value\"]}",
    "meta": {
      "moduleName": "m-corp/routes/components/io/staged-card/template.hbs"
    }
  });

  _exports.default = _default;
});