define("m-corp/routes/components/section-title/component", ["exports", "@glimmer/component", "ember-animated/motions/opacity", "ember-animated"], function (_exports, _component, _opacity, _emberAnimated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SectionTitleComponent = (_dec = Ember.inject.service, (_class = (_temp = class SectionTitleComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      this.transition = this.transition.bind(this);
    }

    get marginX() {
      if (this.args.section.includes('<br>')) {
        return 'ml-30';
      } else {
        return 'ml-24';
      }
    }
    /*
     * sent/received are now working for portfolio to project
     *
     * sent from portfolio shoud remain ih place until covered
     * received to project should wait for card to cover page then fade in
     *
     * sent from project should fade
     * received to porfolio should be in place right away
     *
     * we will need to add the router service and bind this to transitioh
     *
     *
     */


    *transition({
      duration,
      insertedSprites,
      removedSprites,
      sentSprites,
      receivedSprites
    }) {
      // console.log("care hero: ", arguments[0]);
      for (let sprite of insertedSprites) {
        sprite.applyStyles({
          'opacity': '0'
        });
      }

      ;

      for (let sprite of removedSprites) {
        (0, _opacity.fadeOut)(sprite, {
          duration: duration * .25
        });
      }

      ;

      for (let sprite of sentSprites) {
        if (this.router.currentRouteName != 'portfolio.project') {
          (0, _opacity.fadeOut)(sprite, {
            duration: duration * .25
          });
        } else {
          (0, _opacity.fadeOut)(sprite);
        }
      }

      ;

      for (let sprite of receivedSprites) {
        if (this.router.currentRouteName != 'portfolio.project') {
          (0, _opacity.fadeIn)(sprite, {
            duration: 0
          });
        }
      }

      ;
      yield (0, _emberAnimated.wait)(duration * .5);

      for (let sprite of insertedSprites) {
        sprite.applyStyles({
          'z-index': '400'
        });
        new _opacity.Opacity(sprite, {
          from: 0,
          to: 1,
          duration: duration * .5
        }).run();
      }

      ;

      for (let sprite of receivedSprites) {
        if (this.router.currentRouteName === 'portfolio.project') {
          sprite.applyStyles({
            'z-index': '40'
          });
          new _opacity.Opacity(sprite, {
            from: 0,
            to: 1,
            duration: duration * .5
          }).run();
        } //  fadeIn uses sprite.initialComputedStyle which in this case is 1
        //  fadeIn(sprite, {duration: duration * 0.5});
        //  creatae app/motions/opacityFromTo.js as per
        //  https://github.com/ember-animation/ember-animated/issues/218
        //  https://github.com/chrism/ember-animated-container

      }

      ;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SectionTitleComponent;
});