define("m-corp/components/geojson-layer", ["exports", "ember-leaflet/components/geojson-layer"], function (_exports, _geojsonLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _geojsonLayer.default;
    }
  });
});