define("m-corp/routes/portfolio/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9frH2PTm",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"flex flex-col h-full max-w-page\"],[12],[2,\" \"],[2,\"\\n\\n\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"],[10,\"div\"],[14,0,\"flex flex-grow min-h-52\"],[12],[13],[2,\"\\n\"],[8,\"site-footer\",[],[[],[]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "m-corp/routes/portfolio/template.hbs"
    }
  });

  _exports.default = _default;
});