define("m-corp/routes/components/video-bg/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hLmzDnOR",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"isVideoVisible\"]]],[[\"initialInsertion\",\"finalRemoval\",\"duration\",\"use\"],[true,true,1800,[32,0,[\"transition\"]]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"flex w-full h-full\\n                overflow-hidden\\n                bg-center bg-cover w-screen h-screen\"],[12],[2,\"\\n      \"],[11,\"video\"],[24,0,\"opacity-0 background-video w-screen h-screen transition\"],[24,\"onloadedmetadata\",\"this.muted=true\"],[24,\"playsinline\",\"\"],[24,\"autoplay\",\"autoplay\"],[24,\"muted\",\"\"],[24,\"loop\",\"loop\"],[24,\"preload\",\"auto\"],[4,[38,0],[[32,0,[\"initVideo\"]]],null],[12],[2,\"\\n        \"],[10,\"source\"],[14,\"src\",\"assets/videos/millennium.mp4\"],[14,4,\"video/mp4\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,1,\"video-title\"],[14,0,\"video-title fixed w-full p-4 place-self-center\\n               font-trajan text-center text-white\\n               tracking-widest leading-tight\\n               transition-slow opacity-0\"],[12],[2,\"MILLENNIUM’S\"],[10,\"br\"],[12],[13],[10,\"span\"],[14,0,\"whitespace-no-wrap\"],[12],[2,\"OLYMPIC VILLAGE\"],[13],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"animated-if\"]}",
    "meta": {
      "moduleName": "m-corp/routes/components/video-bg/template.hbs"
    }
  });

  _exports.default = _default;
});