define("m-corp/services/scroll-into-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const isInViewport = (e, {
    top: t,
    height: h
  } = e.getBoundingClientRect()) => t <= innerHeight && t + h >= 0;

  let ScrollIntoViewService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, (_class = (_temp = class ScrollIntoViewService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "viewportManager", _descriptor2, this);

      _initializerDefineProperty(this, "activeElementId", _descriptor3, this);

      this.router.on('routeWillChange', transition => {
        this.routeTransitionForScroller(transition);
      });
    }

    routeTransitionForScroller(transition) {
      const forceScrollTopRoutes = ['portfolio.featured', 'portfolio.master-planned', 'portfolio.hospitality', 'portfolio.commercial', 'portfolio.residential', 'portfolio.restoration', 'portfolio.paris', 'portfolio.paris-map', 'portfolio.projects.index', 'about', 'care', 'careers', 'contact']; // if(transition.from){

      if (transition.from.name === 'portfolio.project') {
        const elementId = transition.from.params.project_id;

        if (elementId) {
          Ember.run.scheduleOnce('afterRender', this, 'scrollIntoView', elementId); // console.log('scroll-into-view scheduled scroll-into-view');
        }
      } // }
      // this might be best as else if since we never want to scrollTop
      // when leaving a project detail page
      // if(transition.to){
      else if (forceScrollTopRoutes.includes(transition.to.name)) {
          Ember.run.scheduleOnce('afterRender', this, 'scrollToTop');
        } // }

    }

    scrollToTop() {
      let viewportStage = document.getElementById('viewport-stage');
      viewportStage.scrollTo(0, 0);
      this.viewportManager.updateDestinationViewportElements();
    }

    scrollIntoView(elementId) {
      this.activeElementId = elementId;
      const element = document.getElementById(elementId);

      if (element) {
        //      if(!isInViewport(element)){
        element.scrollIntoView({
          block: 'center'
        }); // this still works when just the tip is visible we should have a threshhold
        // which we can figure about above.
        //
        // this is before EA has started to measure
        //
        // Component constructors haave already run,  is this accurate? cause we are in 'routeWillChange' not 'afterRender'
        // but EA has not calculated animations yet
        //    }
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "viewportManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "activeElementId", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class));
  _exports.default = ScrollIntoViewService;
});