define("m-corp/routes/components/io/image-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7EzRoB4j",
    "block": "{\"symbols\":[\"@isSource\",\"@project\",\"@dst\",\"@src\",\"@animationId\"],\"statements\":[[8,\"animated-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n\"],[6,[37,2],[[30,[36,1],[[32,5],\"source\"],null]],[[\"initialInsertion\",\"finalRemoval\",\"duration\",\"use\"],[true,true,1000,[32,0,[\"transition\"]]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"img\"],[15,0,[31,[\"absolute top-0 object-cover w-full h-full\\n                \",[30,[36,0],[[32,1],\"opacity-100\",\"opacity-0\"],null]]]],[15,\"data-orientation\",[32,0,[\"orientation\"]]],[15,\"alt\",[32,2,[\"name\"]]],[15,\"src\",[32,4]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,5],\"target\"],null]],[[\"initialInsertion\",\"finalRemoval\",\"duration\",\"use\"],[true,true,1000,[32,0,[\"transition\"]]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"img\"],[15,0,[31,[\"absolute top-0 object-cover w-full h-full\\n               \",[30,[36,0],[[32,1],\"opacity-0\",\"z-30 opacity-100\"],null]]]],[15,\"data-orientation\",[32,0,[\"orientation\"]]],[15,\"alt\",[32,2,[\"name\"]]],[15,\"src\",[32,3]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"concat\",\"animated-value\"]}",
    "meta": {
      "moduleName": "m-corp/routes/components/io/image-group/template.hbs"
    }
  });

  _exports.default = _default;
});