define("m-corp/routes/application/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ApplicationRoute extends Ember.Route {
    model() {
      return this.store.findAll('project'); // multi find
    }

  }

  _exports.default = ApplicationRoute;
});