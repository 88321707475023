define("m-corp/routes/components/section-title/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GbpP3xo7",
    "block": "{\"symbols\":[\"&attrs\",\"@section\"],\"statements\":[[6,[37,1],[[32,2]],[[\"initialInsertion\",\"finalRemoval\",\"duration\",\"use\"],[true,true,1600,[32,0,[\"transition\"]]]],[[\"default\"],[{\"statements\":[[11,\"h1\"],[17,1],[16,0,[31,[\"inline-block\\n         text-4xl leading-none uppercase font-poynter\\n         \",[32,0,[\"marginX\"]]]]],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"block text-gold underline-thin transform rotate-90 origin-top-left\"],[12],[1,[30,[36,0],[[32,2]],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"html-safe-string\",\"animated-value\"]}",
    "meta": {
      "moduleName": "m-corp/routes/components/section-title/template.hbs"
    }
  });

  _exports.default = _default;
});