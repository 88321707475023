define("m-corp/routes/components/io/animated-images/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EcLkqUpn",
    "block": "{\"symbols\":[\"@role\",\"@project\",\"@dst\",\"@src\",\"@animationId\"],\"statements\":[[8,\"animated-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n\"],[6,[37,3],[[30,[36,2],[[32,5],\"placeholder\"],null]],[[\"initialInsertion\",\"finalRemoval\",\"duration\",\"use\"],[true,true,1000,[32,0,[\"transition\"]]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"img\"],[15,0,[31,[\"absolute top-0 object-cover w-full h-full\\n                \",[30,[36,1],[[30,[36,0],[[32,1],\"placeholder\"],null],\"opacity-100\",\"opacity-0\"],null]]]],[15,\"data-role\",[32,1]],[15,\"alt\",[32,2,[\"name\"]]],[15,\"src\",[32,4]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,5],\"animator\"],null]],[[\"initialInsertion\",\"finalRemoval\",\"duration\",\"use\"],[true,true,1000,[32,0,[\"transition\"]]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"img\"],[15,0,[31,[\"absolute top-0 object-cover w-full h-full\\n               \",[30,[36,1],[[30,[36,0],[[32,1],\"animator\"],null],\"opacity-100 z-30\",\"opacity-0\"],null]]]],[15,\"data-role\",[32,1]],[15,\"alt\",[32,2,[\"name\"]]],[15,\"src\",[32,3]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"concat\",\"animated-value\"]}",
    "meta": {
      "moduleName": "m-corp/routes/components/io/animated-images/template.hbs"
    }
  });

  _exports.default = _default;
});