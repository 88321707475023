define("m-corp/routes/about/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AboutRoute extends Ember.Route {// model() {
    //   return hash({
    //     citp: this.store.findRecord('project', 'city-in-the-park'),
    //     triomphe: this.store.findRecord('project', 'triomphe'),
    //     olympicVillarg: this.store.findRecord('project', 'olympic-village')
    //   });
    // }
  }

  _exports.default = AboutRoute;
});