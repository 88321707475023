define("m-corp/routes/components/site-menuenter justify-center/component", ["exports", "@glimmer/component", "ember-animated", "ember-animated/motions/move", "ember-animated/motions/opacity"], function (_exports, _component, _emberAnimated, _move, _opacity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SiteMenuComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, (_class = (_temp = class SiteMenuComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "media", _descriptor2, this);

      _initializerDefineProperty(this, "menuItems", _descriptor3, this);

      _initializerDefineProperty(this, "menuBacklight", _descriptor4, this);

      _initializerDefineProperty(this, "menuBacklightLocked", _descriptor5, this);

      _initializerDefineProperty(this, "portfolioMenuItems", _descriptor6, this);

      _initializerDefineProperty(this, "portfolioMenuDelay", _descriptor7, this);

      _initializerDefineProperty(this, "isPortfolioMenuVisible", _descriptor8, this);

      _initializerDefineProperty(this, "portfolioBackground", _descriptor9, this);

      _initializerDefineProperty(this, "initialDistance", _descriptor10, this);

      _defineProperty(this, "isDesktop", undefined);

      this.menuItems = Ember.A([]);
      this.portfolioMenuItems = Ember.A([]);
    }

    get isHome() {
      if (this.args.currentRouteName === 'index') {
        return true;
      } else {
        return false;
      }
    }

    get isPortfolio() {
      if (this.args.currentRouteName.includes('portfolio')) {
        return true;
      } else {
        return false;
      }
    }

    backlightOn() {
      this.menuBacklight = true;
    }

    backlightOff() {
      if (!this.menuBacklightLocked) {
        this.menuBacklight = false;
      }
    } // get menuContent(){
    //   if(this.args.currentRouteName.includes('portfolio')){
    //     if(this.menuItems.length === 0){
    //       this.menuItems.pushObjects(['', 'portfolio']);
    //     }
    //   }
    //   return this.menuItems;
    // }
    //
    // we are overloadign isDesktop so that we don't keep updating on resize
    // isLoaded, isMobileConfig
    //


    menuSetup({
      contentRect: {
        width,
        height
      }
    }) {
      // console.log('width: ', width);
      if (!Ember.isPresent(this.isDesktop) && width < 984 || this.isDesktop && width < 984) {
        this.isDesktop = false;
        this.menuItems = Ember.A([]);

        if (this.args.currentRouteName.includes('portfolio')) {
          this.isPortfolioMenuVisible = true;
          this.portfolioMenuItems = Ember.A(['Featured', 'Master-planned', 'Hospitality', 'Commercial', 'Residential Rental', 'Restoration', 'Paris, France', 'All']);
        }
      }

      if (!Ember.isPresent(this.isDesktop) && width > 984 || !this.isDesktop && width > 984) {
        this.isDesktop = true;

        if (this.args.currentRouteName.includes('portfolio')) {
          if (this.menuItems.length === 0) {
            this.menuItems.pushObjects(['', 'portfolio']);
            this.isPortfolioMenuVisible = true;
            this.portfolioMenuItems = Ember.A(['Featured', 'Master-planned', 'Hospitality', 'Commercial', 'Residential Rental', 'Restoration', 'Paris, France', 'All']);
          }
        } //      this.portfolioMenuItems = A(['Featured', 'Master-planned', 'Hospitality', 'Commercial', 'Residential Rental', 'Restoration', 'Paris, France', 'All']);

      } // then after a slight delay empty our nac
      //
      // above NN go desktop

    }

    *backlightTransition({
      insertedSprites,
      removedSprites
    }) {
      // console.log(arguments[0]);
      for (let sprite of insertedSprites) {
        (0, _opacity.fadeIn)(sprite);
      }

      for (let sprite of removedSprites) {
        (0, _opacity.fadeOut)(sprite);
      }
    }

    *transition({
      insertedSprites,
      removedSprites
    }) {
      // console.log(arguments[0]);
      for (let sprite of insertedSprites.reverse()) {
        sprite.applyStyles({
          'z-index': `${(insertedSprites.length - insertedSprites.indexOf(sprite)) * 100}`
        });
        sprite.startAtPixel({
          y: 0
        });
        (0, _move.default)(sprite);
        yield (0, _emberAnimated.wait)(55);
      }

      for (let sprite of removedSprites) {
        sprite.applyStyles({
          'z-index': `${(removedSprites.length - removedSprites.indexOf(sprite)) * 100}`
        });
        sprite.endAtPixel({
          y: 0
        });
        (0, _move.default)(sprite);
        yield (0, _emberAnimated.wait)(66);
      }
    }

    toggleNav() {
      if (this.menuItems.length > 2) {
        if (this.args.currentRouteName.includes('portfolio')) {
          this.menuItems = Ember.A(['', 'portfolio']);
          this.portfolioMenuDelay = 600;
          this.portfolioMenuItems = Ember.A(['Featured', 'Master-planned', 'Hospitality', 'Commercial', 'Residential Rental', 'Restoration', 'Paris, France', 'All']);
        } else {
          this.menuItems = Ember.A([]);
        }

        this.menuBacklight = false;
      } else {
        // if we are showing the portfolio menu
        // delay for release to allow the portfoli-menu to hide
        // and don't use the backlight (unless the screen is smaller
        // since the visibility is dependant on size think about using css MQs
        if (this.menuItems.length === 2) {
          Ember.run.later(this, function () {
            this.menuItems = Ember.A(['home', 'portfolio', 'customer care', 'careers', 'about', 'contact']);
          }, 500);
          this.menuBacklight = false; // unless this is on a smaller screen.
        } else {
          this.menuItems = Ember.A(['home', 'portfolio', 'customer care', 'careers', 'about', 'contact']);
          this.menuBacklight = true;
        }

        this.portfolioMenuItems = Ember.A([]);
      }
    }

    navigateTo(route) {
      if (route === 'customer care') {
        this.menuItems = Ember.A([]);
        this.router.transitionTo('care');
        Ember.run.later(this, function () {
          this.portfolioScreen = false;
        }, 666);
      } else if (route === 'home') {
        this.menuItems = Ember.A([]);
        this.portfolioScreen = false;
        this.isPortfolioMenuVisible = false;
        this.router.transitionTo('index');
      } else if (route === 'portfolio') {
        this.menuItems = Ember.A(['', 'portfolio']);
        this.router.transitionTo('portfolio.residential'); // later(this, function() {

        this.isPortfolioMenuVisible = true;
        this.portfolioMenuDelay = 1500;
        this.portfolioMenuItems = Ember.A(['Featured', 'Master-planned', 'Hospitality', 'Commercial', 'Residential Rental', 'Restoration', 'Paris, France', 'All']); //}, 666);
      } else {
        this.menuItems = Ember.A([]);
        this.isPortfolioMenuVisible = false;
        this.portfolioScreen = false;
        this.router.transitionTo(route);
      }

      Ember.run.later(this, function () {
        this.menuBacklight = false;
      }, 800);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "media", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "menuItems", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "menuBacklight", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "menuBacklightLocked", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "portfolioMenuItems", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "portfolioMenuDelay", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1500;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isPortfolioMenuVisible", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "portfolioBackground", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "initialDistance", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "backlightOn", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "backlightOn"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "backlightOff", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "backlightOff"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "menuSetup", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "menuSetup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleNav", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "toggleNav"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "navigateTo", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "navigateTo"), _class.prototype)), _class));
  _exports.default = SiteMenuComponent;
});