define("m-corp/routes/components/project-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4p+T32D4",
    "block": "{\"symbols\":[\"@project\",\"@section\"],\"statements\":[[2,\"\\n\"],[10,\"div\"],[15,0,[31,[\"relative flex flex-col\\n            w-1/2 md:w-full\\n            min-w-\",[32,0,[\"cardMinWidth\"]],\"\\n            max-w-\",[32,0,[\"cardMaxWidth\"]],\"\\n            pointer-events-auto\\n            \",[32,0,[\"cardMargins\"]],\"\\n            project-card\\n            p-2\\n            md:p-4 md:mb-6\"]]],[15,1,[31,[[32,1,[\"id\"]]]]],[12],[2,\"\\n\\n\"],[6,[37,0],null,[[\"route\",\"model\"],[\"portfolio.project\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"io/project-card\",[],[[\"@project\",\"@width\",\"@height\",\"@classes\"],[[32,1],[32,0,[\"imageWidth\"]],[32,0,[\"imageHeight\"]],\"absolute top-0 object-cover w-full h-full\\n                shadow-none\"]],null],[2,\"\\n\\n\"],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"relative\"],[15,5,[31,[\"--aspect-ratio:\",[32,0,[\"imageWidth\"]],\"/\",[32,0,[\"imageHeight\"]],\";\"]]],[12],[2,\"\\n\"],[2,\"      \"],[8,\"io/animated-image\",[],[[\"@alt\",\"@forcedMatch\",\"@src\"],[\"Message Stuff\",[32,1,[\"id\"]],[32,0,[\"portfolioImage\"]]]],null],[2,\"\\n\\n    \"],[13],[2,\"\\n    \"],[8,\"io/card-copy\",[],[[\"@section\",\"@project\"],[[32,2],[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\"]}",
    "meta": {
      "moduleName": "m-corp/routes/components/project-card/template.hbs"
    }
  });

  _exports.default = _default;
});