define("m-corp/routes/careers/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8vIakJyv",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[24,0,\"flex flex-col h-full\"],[4,[38,0],null,[[\"background-image\",\"background-size\"],[\"url(\\\"/assets/images/backgrounds/generic-bg@2x.png\\\")\",\"cover\"]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"min-h-80 md:min-h-117 lg:min-h-52-XD\"],[14,5,\"--aspect-ratio:1440/472;\"],[12],[2,\"\\n  \"],[8,\"io/animated-image\",[],[[\"@alt\",\"@src\"],[\"career\",\"/assets/images/careers-hero.jpg\"]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"relative max-w-full lg:w-2/3 mx-4 md:ml-18 md:mr-9 lg:ml-39 pb-44\"],[12],[2,\"\\n    \"],[8,\"section-title\",[[24,0,\"absolute hidden lg:block z-10 mt-16 -ml-14 w-50\"]],[[\"@section\"],[\"careers\"]],null],[2,\"\\n    \"],[8,\"page-title\",[[24,0,\"mt-6 mb-8 md:mt-16 md:mb-10\"]],[[\"@lines\"],[[30,[36,1],[\"MILLENNIUM CONSISTS OF TALENTED\",\"PROFESSIONALS DEDICATED\",\"TO MAKING A DIFFERENCE.\"],null]]],null],[2,\"\\n\\n\\n    \"],[10,\"p\"],[14,0,\"detail-body max-w-95\"],[12],[2,\"Leading the company is an experienced management team with strong values and a clear vision for the future.  There are currently no openings at this time. Be sure to check back for future opportunities.\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"flex flex-grow\"],[12],[13],[2,\"\\n  \"],[8,\"site-footer\",[[24,0,\"mt-9\"]],[[],[]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"style\",\"array\"]}",
    "meta": {
      "moduleName": "m-corp/routes/careers/template.hbs"
    }
  });

  _exports.default = _default;
});