define("m-corp/routes/portfolio/commercial/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PortfolioCommercialController extends Ember.Controller {
    get leftColumn() {
      var left = [];
      this.desktopSort.forEach((el, idx) => {
        if (idx % 2 == 0) {
          left.push(el);
        }
      });
      return left;
    }

    get rightColumn() {
      var right = [];
      this.desktopSort.forEach((el, idx) => {
        if (idx % 2 != 0) {
          right.push(el);
        }
      });
      return right;
    }

    get desktopSort() {
      return this.model.sortBy('commercialSort').filterBy('isCommercial', true);
    }

  }

  _exports.default = PortfolioCommercialController;
});