define("m-corp/services/tracked-viewport-manager", ["exports", "tracked-built-ins"], function (_exports, _trackedBuiltIns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ViewportManagerService = (_dec = Ember.inject.service, (_class = (_temp = class ViewportManagerService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _defineProperty(this, "viewportImages", new _trackedBuiltIns.TrackedArray([]));

      _defineProperty(this, "lastViewportImages", new _trackedBuiltIns.TrackedArray([]));

      _initializerDefineProperty(this, "toggle", _descriptor2, this);

      this.router.on('routeWillChange', transition => {
        this.shiftViewportImages();
      });
    }

    shiftViewportImages() {
      // console.log('trackingIndex: shifting viewportImages');
      this.lastViewportImages = new _trackedBuiltIns.TrackedArray([...this.viewportImages]);
      this.viewportImages.length = 0;
    }

    addElementToViewport(id) {
      // console.log('trackingIndex: adding element: ', id);
      this.viewportImages.push(id); // console.log('trackingIndex: ', this.viewportImages);

      this.toggle = this.viewportImages.firstObject;
    }

    removeElementFromViewport(id) {
      let trimmed = new _trackedBuiltIns.TrackedArray([]);

      for (let i = 0; i < this.viewportImages.length; i++) {
        if (this.viewportImages[i] != id) {
          trimmed.push(this.viewportImages[i]);
        }

        this.viewportImages = trimmed;
      }
    }

    outgoingSpriteIdAtIndex(index) {
      // console.log('outgoing trackingIndex: ', index);
      return this.lastViewportImages[index];
    }

    incomingSpriteIdAtIndex(index) {
      // console.log('incoming trackingIndex: ', index);
      return this.viewportImages[index];
    }

    indexOfOutgoingSprite(id) {
      // console.log('outgoing trackingIndex requested for: ', id);
      return this.lastViewportImages.indexOf(id);
    }

    indexOfIncomingSprite(id) {
      // console.log('incoming trackingIndex requested for: ', id);
      return this.viewportImages.indexOf(id);
    }

    animationIdForOutgoingElement(id) {
      // console.log('outgoing trackingIndex requested for: ', id);
      return this.viewportImages.indexOf(id);
    }

    animationIdForIncomingElement(id) {
      // console.log('incoming trackingIndex requested for: ', id);
      return this.viewportImages.indexOf(id);
    } // on will transition copy viewport images to previousViewportImages
    // then get domrects for each of them
    //
    // initialBoundsForElement(id)
    //
    // I am using the id so that I could retriege teh index/animationId
    // we do not need that anymore.
    //
    // we just need the domrect for the index
    //
    // initialBoundsForIndex(index)
    // finalBoundsForIndex(index)
    //
    // But we will need the id to find the element
    // element.getBoundingClientRect()
    // then startSpriteAtPixel
    // scale
    // along wth
    // endSpriteAtPixel
    // scale
    //
    // there is also startAtSprite that does the above
    // we could get the element in the transition and create
    // the Sprite
    // https://github.com/ember-animation/ember-animated/blob/41b54e791f08d3f444d3d11dd21b27839d3c14ab/tests/unit/motion-test.js#L34
    // https://github.com/ember-animation/ember-animated/blob/31c3bbc949e068c395f2a690d1158744127d71af/addon/components/animated-each.ts#L675
    //
    //  that is was send are receive do
    //  https://github.com/ember-animation/ember-animated/blob/31c3bbc949e068c395f2a690d1158744127d71af/addon/components/animated-each.ts#L675
    //
    //  using the motion service to get farMathes
    //  we are using position rather than preicate to make the match
    //  but perhaps we can try changing the id and send out receive in
    //  more easily, the draw back is that the sprite needs to exist on both sides
    //  vs just one.
    //
    //  we will need a way to disable this for some transitions
    //  - portfolio to project will only move target images
    //    can we use the same route-animation?


  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "toggle", [_trackedBuiltIns.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class));
  _exports.default = ViewportManagerService;
});