define("m-corp/routes/components/portfolio-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EZSYxE5P",
    "block": "{\"symbols\":[\"@project\",\"@isAnimating\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"relative flex flex-col\\n            min-w-\",[32,1,[\"cardMinWidth\"]],\"\\n            max-w-\",[32,1,[\"cardMaxWidth\"]],\"\\n            w-full\\n            \",[32,1,[\"cardMargins\"]],\"\\n            group\"]]],[12],[2,\"\\n\"],[2,\"\\n\"],[6,[37,1],[[32,0,[\"isBlurred\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,0],null,[[\"route\",\"model\"],[\"portfolio.project\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"io/mutating-image\",[],[[\"@project\",\"@alt\",\"@portfolio\",\"@stage\",\"@width\",\"@height\",\"@isAnimating\"],[[32,1],[32,1,[\"name\"]],[32,1,[\"portfolioImage\"]],\"http://iofusion.com/assets/images/triomphe/hero.jpg\",[32,1,[\"portfolioImageWidth\"]],[32,1,[\"portfolioImageHeight\"]],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[2,\"\\n\"],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\",\"if\"]}",
    "meta": {
      "moduleName": "m-corp/routes/components/portfolio-card/template.hbs"
    }
  });

  _exports.default = _default;
});