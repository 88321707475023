define("m-corp/routes/portfolio/projects/more/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hiQcuxZt",
    "block": "{\"symbols\":[\"project\"],\"statements\":[[10,\"div\"],[14,0,\"grid grid-cols-3 gap-4 md:gap-18 lg:gap-23\\n            mx-4 md:mx-8 lg:mr-16 lg:ml-39 xl:mx-39\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"model\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"io/past-project\",[],[[\"@project\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "m-corp/routes/portfolio/projects/more/template.hbs"
    }
  });

  _exports.default = _default;
});