define("m-corp/mirage/fixtures/projects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 'triomphe',
    name: 'Triomphe',
    location: 'BRENTWOOD',
    address: '1880 Gilmore Street, Burnaby',
    description: 'A magnificent architectural landmark and home of unprecedented design situated in the heart of Burnaby’s vibrant and growing Brentwood community. Anchoring the tower is a separate three-storey pavilion featuring world-class amenities.',
    completionDate: 'Completion | 2020',
    type: 'RESIDENTIAL',
    pageLayout: 'layout-triomphe',
    heroBackground: 'triomphe-bg.png',
    heroHeadlineFirst: 'SOLD',
    heroHeadlineSecond: 'OUT',
    isSelling: true,
    website: 'http://triompheresidences.com',
    detailTitle: 'TOWERING 47 STOREYS ABOVE A VIBRANT AND GROWING COMMUNITY',
    detailCopyOne: 'A magnificent architectural landmark and home of unprecedented design situated in the heart of Burnaby’s vibrant and growing Brentwood community. Anchoring the tower is a separate three-storey pavilion featuring world-class amenities.',
    detailCopyTwo: 'Triomphe is a home of distinction offering a quality of life beyond compare. The exquisitely designed 1, 2 and 3 bedroom residences embody sophistication and style. Bold contemporary elements pair with a high level of finishes. Spacious open floor plans flow onto expansive balconies, while stunning views provide an inspiring backdrop for everyday life.',
    detailCopyFour: 'Completing the residential masterpiece are world-class amenities. The separate three-storey pavilion features a large resort-style social lounge, business centre, games room, modern fitness facilities, steam rooms and a peaceful yoga studio. On the roof is a magnificent outdoor pool and landscaped terrace.',
    isAll: 'true',
    isFeatured: 'true',
    isFiller: 'true',
    projectCategories: [0, 7],
    featureImage: '/assets/images/triomphe/feature@1x.jpg',
    featureImageWidth: '180',
    featureImageHeight: '121',
    portfolioImage: '/assets/images/triomphe/portfolio@1x.jpg',
    portfolioImageWidth: '101',
    portfolioImageHeight: '147',
    portfolioMobileImageHeight: '150',
    portfolioImageMinWidth: '44',
    // since we are using pixels we can use scalibility
    portfolioSpacer: 'w-635/1000',
    featuredImageHeight: '147',
    featuredMobileImageHeight: '157',
    fillerImage: '/assets/images/triomphe/filler@1x.jpg',
    fillerImageWidth: '123',
    fillerImageHeight: '114',
    fillerMobileImageHeight: '107',
    fillerSpacer: 'w-500/1000',
    cardMargins: 'md:mt-14',
    heroImage: '/assets/images/triomphe/feature@1x.jpg',
    photoIds: [],
    sort: 1,
    featuredSort: 1,
    isFocused: false
  }, {
    id: 'etoile',
    name: 'Étoile',
    location: 'BRENTWOOD',
    address: '5343 Goring Street, Burnaby',
    description: 'A star within the Brentwood community. The paired towers, curvilinear architecture, and stately porte cochere entry create a refined sense of place. The fourth floor rooftop podium encompasses an array of lifestyle amenities and gardens.',
    completionDate: 'Completion | 2020',
    type: 'RESIDENTIAL',
    pageLayout: 'layout-etoile',
    heroBackground: 'etoile-bg.png',
    heroHeadlineFirst: 'SOLD',
    heroHeadlineSecond: 'OUT',
    isSelling: true,
    website: 'http://etoileliving.com',
    detailTitle: 'A STAR WITHIN THE BRENTWOOD COMMUNITY',
    detailCopyOne: 'Two boutique towers redefine modern luxury living with spacious, elegant residences and multi-level townhomes in a stellar location just minutes from world-class shopping, dining and entertainment.',
    detailCopyTwo: 'The paired 26 and 32 storey towers, curvilinear architecture, and stately porte cochere entry create a refined sense of place. On the fourth floor podium is a tranquil and elegant oasis dedicated to lifestyle amenities and gardens.',
    detailCopyThree: 'Living just minutes from Brentwood Town Centre, Holdom Skytrain Station and the 770-acre Burnaby Lake Regional Park offers an exceptional lifestyle.',
    detailCopyFour: 'The lobby is world class with double height ceilings, grand pillars and a modern decor. Fob controlled access and a concierge add peace of mind.',
    detailCopyFive: 'Each tower has its own fitness center and social lounge, and there is a shared outdoor pool, hot tub, and expansive sundeck with spectacular views.',
    featureImage: '/assets/images/etoile/feature@1x.jpg',
    featureImageWidth: '180',
    featureImageHeight: '121',
    isAll: 'true',
    isFeatured: 'true',
    isFiller: 'true',
    projectCategories: [0, 7],
    portfolioImage: '/assets/images/etoile/porfolio@1x.jpg',
    portfolioImageWidth: '123',
    portfolioImageHeight: '96',
    portfolioImageMinWidth: '49',
    portfolioSpacer: 'w-535/1000',
    fillerImage: '/assets/images/etoile/filler@1x.jpg',
    fillerImageWidth: '79',
    fillerImageHeight: '131',
    fillerSpacer: 'w-689/1000',
    cardMargins: '',
    photoIds: [],
    sort: 2,
    featuredSort: 5,
    isFocused: false
  }, {
    id: 'lonsdale',
    name: 'Millennium Central Lonsdale',
    location: 'NORTH VANCOUVER',
    address: '125 East 13th Street, North Vancouver',
    description: 'A mixed-use development located in North Vancouver’s vibrant Central Lonsdale neighbourhood. Features 162 residences, including condos and townhomes, plus 18,331 square feet of ground level retail and 31,700 square feet of office space.',
    completionDate: 'Completion | 2024',
    type: 'RESIDENTIAL',
    commercialType: 'COMMERCIAL',
    heroBackground: 'lonsdale-bg.png',
    heroHeadlineFirst: 'COMING',
    heroHeadlineSecond: 'SOON',
    isSelling: true,
    website: 'http://millenniumlonsdale.com',
    detailCopyOne: 'A mixed-use development located in North Vancouver’s vibrant Central Lonsdale neighbourhood. Features 162 residences, including condos and townhomes, plus 18,331 square feet of ground level retail and 31,700 square feet of office space.',
    featureImage: '/assets/images/lonsdale/feature@1x.jpg',
    featureImageWidth: '180',
    featureImageHeight: '121',
    isAll: 'true',
    isFeatured: 'true',
    isCommercial: 'true',
    projectCategories: [0, 3, 7],
    portfolioImage: '/assets/images/lonsdale/portfolio@2x.png',
    portfolioImageWidth: '128',
    portfolioImageHeight: '81',
    portfolioImageMinWidth: '51',
    portfolioSpacer: 'w-435/1000',
    commercialSpacer: 'w-525/1000',
    featuredImage: '/assets/images/lonsdale/featured@2x.jpg',
    featuredImageWidth: '133',
    featuredImageHeight: '93',
    featuredSpacer: 'w-400/1000',
    cardMargins: 'md:mr-16 xl:mr-22',
    photoIds: [],
    sort: 4,
    commercialSort: 3,
    featuredSort: 2,
    isFocused: false
  }, {
    id: 'boheme',
    name: 'Bohème',
    location: 'VANCOUVER',
    address: '1588 East Hastings Street, Vancouver',
    description: 'A bohemian rhapsody of sophistication and style. The elegant mixed-use project features 102 white brick residences and 24,000 square feet of retail and office space in the vibrant and sought-after Commercial Drive neighbourhood.',
    completionDate: 'Completed | 2015',
    type: 'RESIDENTIAL',
    commercialType: 'COMMERCIAL',
    pageLayout: 'layout-boheme',
    detailTitle: 'A BOHEMIAN RHAPSODY OF SOPHISTICATION AND STYLE',
    detailCopyOne: 'An elegant mixed-use project featuring 102 white brick residences and 24,000 square feet of first-class retail and office space in the vibrant and highly sought-after Commercial Drive neighbourhood.',
    detailCopyTwo: 'There’s a timeless elegance to the white brick neighbourhood, which encompasses 82 homes ranging from junior one bedroom to two-bedroom suites.  Modern stylish interiors combine with generous private outdoor space to create a truly unique lifestyle.',
    detailCopyFour: 'Shops and restaurants are woven into the Bohème community. Loblaw’s - No Frills grocery store is steps away and the vibrant Commercial Drive neighbourhood offers a host of amenities and one-of-a-kind atmosphere.',
    featureImage: '/assets/images/boheme/feature@1x.jpg',
    featureImageWidth: '180',
    featureImageHeight: '121',
    isAll: 'true',
    isCommercial: 'true',
    isResidential: 'true',
    projectCategories: [3, 4, 7],
    portfolioImage: '/assets/images/boheme/portfolio@1x.jpg',
    portfolioImageWidth: '123',
    portfolioImageHeight: '114',
    portfolioImageMinWidth: '49',
    portfolioSpacer: 'w-545/1000',
    residentialImageHeight: '114',
    residentialMobileImageHeight: '157',
    commercialSpacer: 'w-545/1000',
    residentialSpacer: 'w-540/1000',
    cardMargins: '',
    photoIds: [],
    sort: 3,
    commercialSort: 1,
    residentialSort: 4,
    isFocused: false
  }, {
    id: 'gastown',
    name: 'Millennium Gastown',
    location: 'VANCOUVER',
    address: '1 West Hastings Street',
    description: 'Retail and office space in the fully restored and modernized 1913 Merchants Bank Building in Vancouver’s historic and trendy Gastown. The area has high pedestrian and vehicle traffic, and the corner location offers excellent visibility.',
    completionDate: 'Completed | 2018',
    type: 'RESTORATION',
    commercialType: 'COMMERCIAL',
    pageLayout: 'layout-gastown',
    heroHeadlineFirst: 'AVAILABLE',
    heroHeadlineSecond: 'FOR LEASE',
    detailTitle: 'THE CORNER LOCATION OFFERS EXCELLENT VISIBILITY COMBINED WITH HIGH PEDESTRIAN AND VEHICLE TRAFFIC',
    detailCopyOne: 'Retail and office space ranging from 2,691-13,213 square feet for lease in a restored freestanding heritage building in the heart of historic Gastown, one of Vancouver\'s oldest neighbourhoods and a trendy place to work and live.',
    detailCopyFour: 'The restoration blends heritage architectural elements like the reclaimed limestone façade with modern finishes and new electrical and mechanical systems. Large windows front both West Hastings Street and Carrall Street filling the interior with natural light.',
    featureImage: '/assets/images/gastown/feature@1x.jpg',
    featureImageWidth: '180',
    featureImageHeight: '121',
    restorationMobileImageHeight: '222',
    isAll: 'true',
    isFeatured: 'true',
    isCommercial: 'true',
    isRestoration: 'true',
    projectCategories: [0, 3, 5, 7],
    portfolioImage: '/assets/images/gastown/portfolio.2@1x.jpg',
    portfolioImageWidth: '79',
    portfolioImageHeight: '131',
    portfolioImageMinWidth: '31',
    portfolioSpacer: 'w-691/1000',
    featuredImage: '/assets/images/gastown/featured@1x.jpg',
    featuredImageWidth: '159',
    featuredImageHeight: '106',
    featuredSpacer: 'w-400/1000',
    featuredMobileImageHeight: '116',
    restorationImage: '/assets/images/gastown/restoration@1x.jpg',
    restorationImageWidth: '155',
    restorationImageHeight: '93',
    restorationSpacer: 'w-440/1000',
    commercialSpacer: 'w-691/1000',
    cardMargins: '',
    photoIds: [],
    sort: 6,
    featuredSort: 2,
    commercialSort: 4,
    isFocused: false
  }, {
    id: 'city-in-the-park',
    name: 'City in the Park',
    location: 'BURNABY',
    address: 'Award for Excellence in Urban Development,<br> Award of Merit, National SAM Finalist',
    description: '7 towers spread over 16 acres, 85% of which is dedicated to parks and gardens. The expansive landscaping and central gardens create a feeling of space, security and oneness with nature.',
    completionDate: 'Completed | 1991 - 2007',
    type: 'MASTER-PLANNED',
    pageLayout: 'layout-citp',
    heroBackground: 'city-in-the-park-bg.png',
    detailTitle: 'OVER 85% OF THE LAND IS DEDICATED TO PARKS AND GARDENS',
    detailCopyOne: 'City in the Park encompasses 7 towers spread over 16 acres in south Burnaby. Extensive landscaping and central gardens create a stunning, peaceful and highly livable environment.',
    detailCopyTwo: 'The focal point of the community is the huge Versailles-style formal garden with its signature entrance gate, reflecting pool adorned with fountains, and grand staircase.',
    detailCopyThree: 'The entire park is framed by double rows of maple trees planted on either side of a pedestrian pathway, surrounding the community with an “alley” of greenery.',
    detailCopyFour: 'The simple yet formal pattern of clipped hedging, lawns, annuals and swirls of roses are woven together creating a feeling of space, security and oneness with nature.',
    detailCopyFive: 'With 7 concrete high-rise towers, the picturesque community is home to more than 1600 households.',
    detailCopySix: 'Formal gardens, natural green space and family friendly amenity areas are integrated into the landscaping.',
    detailCopySeven: 'The master-planned community brilliantly combines quality, accessibility and livability with nature.',
    featureImage: '/assets/images/city-in-the-park/feature@1x.jpg',
    featureImageWidth: '180',
    featureImageHeight: '121',
    isAll: 'true',
    isAbout: 'true',
    isMasterPlanned: 'true',
    isCommercial: 'true',
    isResidential: 'true',
    isFiller: 'true',
    projectCategories: [1, 3, 4, 7],
    portfolioImage: '/assets/images/city-in-the-park/portfolio@1x.jpg',
    portfolioImageWidth: '180',
    portfolioImageHeight: '106',
    portfolioImageMinWidth: '70',
    portfolioSpacer: 'w-355/1000',
    aboutImage: '/assets/images/city-in-the-park/portfolio@1x.jpg',
    aboutImageWidth: '180',
    aboutImageHeight: '106',
    aboutImageMinWidth: '70',
    aboutSpacer: 'w-355/1000',
    masterplanImage: '/assets/images/city-in-the-park/masterplan@1x.jpg',
    masterplanImageWidth: '102',
    masterplanImageHeight: '147',
    masterplanImageMinWidth: '50',
    masterplanSpacer: 'w-630/1000',
    fillerImage: '/assets/images/city-in-the-park/filler@1x.jpg',
    fillerImageWidth: '123',
    fillerImageHeight: '114',
    fillerSpacer: 'w-565/1000',
    commercialSpacer: 'w-355/1000',
    residentialSpacer: 'w-475/1000',
    cardMargins: '',
    photoIds: [],
    sort: 5,
    commercialSort: 3,
    residentialSort: 6,
    isFocused: false
  }, {
    id: 'edgewater',
    name: 'Edgewater',
    location: 'WEST VANCOUVER',
    address: '2288 Belleville Avenue, West Vancouver',
    description: 'A multi-award winning development designed in the spirit of grand turn-of-the-century apartments. Poised above a waterfront promenade, the elegant concrete tower features 12 one-per-floor residences and 3 townhomes.',
    completionDate: 'Completed | 1999',
    type: 'RESIDENTIAL',
    pageLayout: 'layout-edgewater',
    detailTitle: 'THE BEAUTY OF ELEGANT SINGLE-LEVEL LIVING IN WEST VANCOUVER',
    detailCopyOne: 'A multi-award winning development designed by one of the world’s leading architects, Robert A.M. Stern with LDA Architects, in the spirit of grand turn-of-the-century apartments.',
    detailCopyTwo: 'Set against the dramatic backdrop of mountains and poised above a waterfront promenade, the concrete high rise features 12 residences, one per floor, plus 3 townhomes.',
    detailCopyThree: 'The single floor residences are organized around a grand central gallery that connects the principal rooms and draws attention to the stunning panoramas of the city, ocean and sunsets.',
    detailCopyFour: 'Interiors feature ceilings in excess of 10 feet high, classical pilasters and inlaid marble floors.',
    detailCopyFive: 'Floor plans include a “backstairs wing” reminiscent of the gracious mansions of the past.',
    detailCopySix: 'The elegant 13-storey tower is clad in cast stone and is a distinctive landmark on the West Vancouver waterfront.',
    featureImage: '/assets/images/edgewater/feature@1x.jpg',
    featureImageWidth: '180',
    featureImageHeight: '121',
    isAll: 'true',
    projectCategories: [7],
    portfolioImage: '/assets/images/edgewater/portfolio@1x.jpg',
    portfolioImageWidth: '79',
    portfolioImageHeight: '137',
    portfolioImageMinWidth: '31',
    portfolioSpacer: 'w-691/1000',
    cardMargins: '',
    photoIds: [],
    sort: 8,
    isFocused: false
  }, {
    id: 'olympic-village',
    name: 'Millennium’s Olympic Village',
    location: 'VANCOUVER',
    address: '1693 Manitoba Street',
    description: 'Designed and built in a record 30 months for the 2010 Winter Olympic Games, Millennium’s Olympic Village encompasses 8 city blocks, 9 acres of parkland, 21 multi-storey buildings and 70,000 square feet of retail space.',
    completionDate: 'Completed | 2010',
    type: 'MASTER-PLANNED',
    pageLayout: 'layout-olympic-village',
    heroBackground: 'generic-bg.png',
    detailTitle: 'CANADA’S FIRST LEED PLATINUM COMMUNITY',
    detailCopyOne: 'Millennium’s Olympic Village was designed and built in a record 30 months to provide housing and amenities for over 2,800 athletes and officials during the 2010 Winter Olympic Games.',
    detailCopyTwo: 'Millennium’s Olympic Village has been recognized with multiple awards for excellence in urban sustainability, energy and environmental design.',
    detailCopyFour: 'Considered by many to be one of the finest communities in the world from an ecological and architectural perspective, Millennium’s Olympic Village covers 25 acres and 8 city blocks, and encompasses 9 acres of parkland, 21 multi-storey buildings and 70,000 square feet of retail space.',
    detailCopyFive: 'Timeless design combined with the premier waterfront location and amenities creates a sense of community.',
    detailCopySix: 'Homes are built to the highest standards of quality with environmental features designed to reduce energy usage and lower utility bills by up to 40%.',
    detailCopySeven: 'Residences offer improved comfort and cleaner fresh air due to non-toxic materials and efficient state-of-the-art heating, cooling and ventilation systems. Reduced water use is achieved through high efficiency plumbing fixtures and a system of rainwater storage, treatment and recycling.',
    featureImage: '/assets/images/olympic-village/feature@1x.jpg',
    featureImageWidth: '180',
    featureImageHeight: '121',
    isAll: 'true',
    isMasterPlanned: 'true',
    isCommercial: 'true',
    isResidential: 'true',
    isFiller: 'true',
    projectCategories: [1, 3, 4, 7],
    portfolioImage: '/assets/images/olympic-village/portfolio@1x.jpg',
    portfolioImageWidth: '122',
    portfolioImageHeight: '114',
    portfolioImageMinWidth: '49',
    portfolioSpacer: 'w-550/1000',
    masterplanImage: '/assets/images/olympic-village/masterplan@2x.jpg',
    masterplanImageWidth: '166',
    masterplanImageHeight: '93',
    masterplanMobileImageHeight: '239',
    masterplanImageMinWidth: '50',
    masterplanSpacer: 'w-400/1000',
    commercialImageHeight: '114',
    // necessary because of section/mobile height code
    commercialMobileImageHeight: '149',
    residentialImage: '/assets/images/olympic-village/residential@1x.jpg',
    residentialImageWidth: '123',
    residentialImageHeight: '96',
    residentialMobileImageHeight: '99',
    residentialImageMinWidth: '50',
    residentialSpacer: 'w-540/1000',
    fillerImage: '/assets/images/olympic-village/filler@1x.jpg',
    fillerImageWidth: '126',
    fillerImageHeight: '93',
    fillerMobileImageHeight: '117',
    fillerSpacer: 'w-550/1000',
    commercialSpacer: 'w-550/1000',
    cardMargins: '',
    photoIds: [],
    sort: 7,
    commercialSort: 6,
    residentialSort: 1,
    isFocused: false
  }, {
    id: 'hermitage',
    name: 'L’Hermitage',
    location: 'VANCOUVER',
    address: '768-794 & 788 Richards Street - 463-493 Robson Street <br>2010 Housing Award, CMCH<span class="font-light"> - Recognizing Leadership from the Private Sector</span>',
    description: 'L’Hermitage captures the essence of comfort and luxury. Situated downtown Vancouver, the 28-storey concrete tower encompasses a 60-suite luxury boutique hotel, 182 residential suites, and 50,000 square feet of commercial space.',
    completionDate: 'Completed | 2008',
    type: 'HOSPITALITY',
    commercialType: 'COMMERCIAL',
    pageLayout: 'layout-hermitage',
    heroBackground: 'hermitage-bg.png',
    detailTitle: 'UNPARALLELED LEVEL OF SERVICE AND DISTINGUISHED ROOM AMENITIES',
    detailCopyOne: 'L’Hermitage captures the essence of comfort and luxury. Situated downtown Vancouver, the 28-storey concrete tower encompasses a 60-suite luxury boutique hotel, 182 residential suites, and 50,000 square feet of commercial space.',
    detailCopyTwo: 'Residents have access to Hotel facilities, including the fitness club and whirlpool spa, L’Orangerie lounge and library, boardroom, landscaped roof gardens, and daily continental breakfast. Shops, restaurants and nightlife are steps away.',
    featureImage: '/assets/images/hermitage/feature@1x.jpg',
    featureImageWidth: '180',
    featureImageHeight: '121',
    isAll: 'true',
    isHospitality: 'true',
    isCommercial: 'true',
    isFiller: 'true',
    projectCategories: [2, 3, 7],
    portfolioImage: '/assets/images/hermitage/portfolio@1x.jpg',
    portfolioImageWidth: '102',
    portfolioImageHeight: '131',
    portfolioImageMinWidth: '49',
    portfolioSpacer: 'w-635/1000',
    hospitalityImage: '/assets/images/hermitage/hospitality@1x.jpg',
    hospitalityImageWidth: '102',
    hospitalityImageHeight: '147',
    hospitalitySpacer: 'w-650/1000',
    fillerImage: '/assets/images/hermitage/filler@1x.jpg',
    fillerImageWidth: '155',
    fillerImageHeight: '93',
    fillerSpacer: 'w-475/1000',
    commercialSpacer: 'w-635/1000',
    cardMargins: '',
    photoIds: [],
    sort: 10,
    commercialSort: 5,
    isFocused: false
  }, {
    id: 'lumiere',
    name: 'Lumière',
    location: 'VANCOUVER',
    address: '1863-1871 Alberni Street, Vancouver',
    description: 'Lumière sheds new light on the meaning of luxury.  Situated in beautiful Coal Harbour, the 19-storey concrete tower encompasses a limited edition of residences and every home has a corner view.',
    completionDate: 'Completed | 2002',
    type: 'RESIDENTIAL',
    pageLayout: 'layout-lumiere',
    detailTitle: 'BOLD, ORIGINAL, AND BUILT WITH A PASSION FOR PERFECTION',
    detailCopyOne: 'Lumière sheds new light on the meaning of luxury. Situated in beautiful Coal Harbour, every detail in this limited edition of residences has been chosen with care and every home has a corner view.',
    detailCopyTwo: 'A one-of-a-kind development, Lumière has been recognized with two Georgie Awards – Best High Rise Development and Best Townhouse – in addition to an Award for Excellence from the Urban Development Institute.',
    detailCopyThree: 'Tumbling water and luminous reflecting ponds shape the illusion of a floating structure.',
    detailCopyFour: 'The 19-storey concrete tower encompasses 66 suites and 4 townhomes. Interiors are thoughtfully designed and impeccably finished.',
    detailCopyFive: 'Landscaping is an elegant fusion of east meets west featuring Japanese maples, wisteria and boxwood.',
    featureImage: '/assets/images/lumiere/feature@1x.jpg',
    featureImageWidth: '180',
    featureImageHeight: '133',
    isAll: 'true',
    projectCategories: [7],
    portfolioImage: '/assets/images/lumiere/portfolio@1x.jpg',
    portfolioImageWidth: '140',
    portfolioImageHeight: '96',
    portfolioImageMinWidth: '56',
    portfolioSpacer: 'w-490/1000',
    cardMargins: '',
    photoIds: [],
    sort: 9,
    isFocused: false
  }, {
    id: 'alexandra',
    name: 'Alexandra Living',
    location: 'VANCOUVER',
    address: '1221 Bidwell Street, Vancouver',
    description: 'A modern tower built to LEED® gold standard: the existing heritage façade is integrated with contemporary green architecture. Residents can walk to popular English Bay restaurants, the beach and seawall.',
    completionDate: 'Completed | 2013',
    type: 'RESIDENTIAL',
    commercialType: 'COMMERCIAL',
    pageLayout: 'layout-alexandra',
    detailTitle: 'LUXURY LIVING IN VANCOUVER\'S ORIGINAL SEASIDE NEIGHBOURHOOD',
    detailCopyOne: 'Built to LEED® gold standard, the visionary design integrates an existing heritage façade with contemporary green architecture. The modern tower has 85 homes and street-level retail shops, and is located steps from English Bay restaurants, the beach and seawall.',
    detailCopyTwo: 'Residences range from studios to three-bedroom penthouse suites. Interiors are spacious and modern with opulent finishings and spectacular views.',
    detailCopyFour: 'Situated within walking distance of Vancouver’s most popular beach, famous seawall and Stanley Park as well as shops, restaurants and entertainment offers a much-coveted lifestyle',
    featureImage: '/assets/images/alexandra/feature@1x.jpg',
    featureImageWidth: '180',
    featureImageHeight: '121',
    isAll: 'true',
    isCommercial: 'true',
    isResidential: 'true',
    projectCategories: [3, 4, 7],
    portfolioImage: '/assets/images/alexandra/portfolio@1x.jpg',
    portfolioImageWidth: '141',
    portfolioImageHeight: '114',
    portfolioImageMinWidth: '56',
    portfolioSpacer: 'w-485/1000',
    residentialImage: '/assets/images/alexandra/residential@1x.jpg',
    residentialImageWidth: '123',
    residentialImageHeight: '99',
    residentialImageMinWidth: '50',
    residentialSpacer: 'w-540/1000',
    commercialSpacer: 'w-485/1000',
    cardMargins: '',
    photoIds: [],
    sort: 12,
    commercialSort: 7,
    residentialSort: 2,
    isFocused: false
  }, {
    id: 'oma-2',
    name: 'One Madison Avenue',
    location: 'BURNABY',
    address: '4208-4250 Dawson Street, Burnaby',
    description: '3 towers situated steps away from Brentwood Town Centre, OMA encompasses 134 suites, 12 townhouses, 8 commercial retail units, and upscale resident amenities. The master-planned community was awarded a Grand Georgie.',
    completionDate: 'Completed | 2015',
    type: 'RESIDENTIAL',
    commericalType: 'COMMERCIAL',
    pageLayout: 'layout-oma-2',
    detailTitle: 'STEPS AWAY FROM SHOPS, RESTAURANTS AND LIFESTYLE AMENITIES',
    detailCopyOne: 'Winner of the prestigious Grand Georgie Award, OMA is recognized as one of the finest master-planned communities in Burnaby\'s Brentwood area. One Madison Avenue 2 encompasses 134 suites, 12 townhouses and 8 commercial retail units.',
    detailCopyTwo: 'Residents enjoy a prime location across the street from Brentwood Town Centre plus convenient access to two Skytrain stations. Commuting to downtown Vancouver is quick and easy.',
    detailCopyThree: 'Interiors feature rare over-height ceilings, bespoke kitchen and bathroom finishes, stainless steel appliances and granite countertops.',
    detailCopyFour: 'Resident amenities include an indoor swimming pool, steam room, exercise facility and an expansive outdoor raised garden area.',
    featureImage: '/assets/images/oma-2/feature@1x.jpg',
    featureImageWidth: '180',
    featureImageHeight: '121',
    isAll: 'true',
    isCommercial: 'true',
    projectCategories: [3, 7],
    portfolioImage: '/assets/images/oma-2/portfolio@1x.jpg',
    portfolioImageWidth: '102',
    portfolioImageHeight: '131',
    portfolioImageMinWidth: '41',
    portfolioSpacer: 'w-636/1000',
    commercialSpacer: 'w-636/1000',
    cardMargins: '',
    photoIds: [],
    sort: 11,
    commercialSort: 8,
    isFocused: false
  }, {
    id: 'belvedere',
    name: 'Belvedere',
    location: 'BURNABY',
    address: '6823 Station Hill Drive',
    description: 'Awarded Sustainable Community of the Year, the 22-storey tower has a distinctive lobby and 114 elegant residences. Landscaping includes classic European gardens, a 75-foot reflecting pool and hundreds of rose bushes.',
    completionDate: 'Completed | 2007',
    type: 'RESIDENTIAL',
    isAll: 'false',
    projectCategories: [7],
    portfolioImage: '/assets/images/belvedere/portfolio@1x.jpg',
    portfolioImageWidth: '123',
    portfolioImageHeight: '114',
    portfolioImageMinWidth: '41',
    portfolioSpacer: 'w-545/1000',
    cardMargins: '',
    photoIds: [],
    sort: 14,
    isFocused: false
  }, {
    id: 'waters-edge',
    name: 'Water’s Edge',
    location: 'WEST VANCOUVER',
    address: '508-568 Water’s Edge Crescent, West Vancouver',
    description: 'An upscale development in West Vancouver encompassing 79 luxury residences and 16 rental apartments in 4 mid-rise concrete buildings. Received a National Sam Award for Best Multi-Family Housing in Canada.',
    completionDate: 'Completed | 2007',
    type: 'RESIDENTIAL',
    pageLayout: 'layout-watersedge',
    detailTitle: 'LUXURY CONCRETE COMMUNITY BUILT ADJACENT TO THE CAPILANO RIVER',
    detailCopyOne: 'An upscale development in West Vancouver, designed by Robert A.M. Stern of New York with LDA Architects, encompassing 79 luxury residences and 16 rental apartments in 4 mid-rise concrete buildings. Received a National Sam Award for Best Multi-Family Housing in Canada.',
    detailCopyTwo: 'Water’s Edge enjoys a premier West Vancouver location, just steps from Park Royal Shopping Centre and riverfront trails. Quick access to the Lion’s Gate Bridge and Sea-to-Sky highway makes it just as easy to go downtown Vancouver as to Whistler.',
    detailCopyThree: 'Open concept floor plans with beautifully designed hardwood floors are perfect for entertaining.',
    detailCopyFour: 'Top-of-the-line finishings include Eggersmann cabinetry from Germany, a Sub-Zero fridge and Miele appliances, a limestone fireplace, and Dornbracht plumbing fixtures.',
    isAll: 'true',
    isResidential: 'true',
    projectCategories: [4, 7],
    featureImage: '/assets/images/waters-edge/feature@1x.jpg',
    featureImageWidth: '180',
    featureImageHeight: '133',
    portfolioImage: '/assets/images/waters-edge/portfolio@1x.jpg',
    portfolioImageWidth: '134',
    portfolioImageHeight: '99',
    portfolioImageMinWidth: '53',
    portfolioSpacer: 'w-510/1000',
    residentialImage: '/assets/images/waters-edge/residential@1x.jpg',
    residentialImageWidth: '126',
    residentialImageHeight: '79',
    residentialImageMinWidth: '50',
    residentialSpacer: 'w-530/1000',
    cardMargins: '',
    photoIds: [],
    sort: 13,
    residentialSort: 3,
    isFocused: false
  }, {
    id: '333-carrall',
    name: '333 Carrall',
    location: 'VANCOUVER',
    address: '',
    description: 'The 7-storey mixed-use project offers commercial space and rental studios in the heart of Gastown, a vibrant inner city neighbourhood undergoing gentrification. A portion of the buildings’ heritage façade is being preserved.',
    completionDate: 'Completed | ????',
    commercialType: 'COMMERCIAL',
    residentialType: 'RESIDENTIAL',
    //    isCommercial: 'true',
    //    isResidential: 'true',
    projectCategories: [3, 4],
    portfolioImage: '/assets/images/carrall/portfolio@1x.jpg',
    portfolioImageWidth: '134',
    portfolioImageHeight: '94',
    portfolioImageMinWidth: '53',
    portfolioSpacer: 'w-510/1000',
    featuredImage: '/assets/images/carrall/featured@1x.jpg',
    featuredImageWidth: '134',
    featuredImageHeight: '99',
    featuredImageMinWidth: '53',
    featuredSpacer: 'w-510/1000',
    commercialImage: '/assets/images/carrall/commercial@1x.jpg',
    commercialImageWidth: '141',
    commercialImageHeight: '96',
    commercialImageMinWidth: '53',
    commercialSpacer: 'w-510/1000',
    residentialImage: '/assets/images/carrall/residential@1x.jpg',
    residentialImageWidth: '79',
    residentialImageHeight: '79',
    residentialImageMinWidth: '50',
    residentialSpacer: 'w-687/1000',
    cardMargins: '',
    photoIds: [],
    sort: 13,
    commercialSort: 10,
    residentialSort: 5,
    isFocused: false
  }, {
    id: 'windsor-square',
    name: 'Windsor Square',
    location: 'WHITE ROCK',
    address: '',
    description: 'Recipient of the Urban Development Institute’s Award of Excellence, Windsor Square Shopping Centre offers prime office and retail space in the heart of the new White Rock town centre.',
    completionDate: 'Completed | ????',
    type: 'COMMERCIAL',
    //isCommercial: 'true',
    projectCategories: [3],
    portfolioImage: '/assets/images/windsor-square/portfolio@1x.jpg',
    portfolioImageWidth: '102',
    portfolioImageHeight: '116',
    portfolioImageMinWidth: '53',
    portfolioSpacer: 'w-510/1000',
    commercialImage: '/assets/images/windsor-square/commercial@1x.jpg',
    commercialImageWidth: '102',
    commercialImageHeight: '116',
    commercialImageMinWidth: '53',
    commercialSpacer: 'w-510/1000',
    cardMargins: '',
    photoIds: [],
    sort: 13,
    commercialSort: 9,
    isFocused: false
  }, {
    id: 'sunwood-square',
    name: 'Sunwood Square',
    location: 'COQUITLAM',
    address: '',
    description: 'The 220,000-square-foot shopping centre has the distinction of being home to the first Safeway Superstore in Canada and has been a major catalyst in the growth of the area.',
    completionDate: 'Completed | ????',
    type: 'COMMERCIAL',
    //   isCommercial: 'true',
    projectCategories: [3],
    portfolioImage: '/assets/images/sunwood-square/portfolio@1x.jpg',
    portfolioImageWidth: '134',
    portfolioImageHeight: '99',
    portfolioImageMinWidth: '53',
    portfolioSpacer: 'w-510/1000',
    commercialImage: '/assets/images/sunwood-square/commercial@1x.jpg',
    commercialImageWidth: '102',
    commercialImageHeight: '82',
    commercialImageMinWidth: '53',
    commercialSpacer: 'w-510/1000',
    cardMargins: '',
    photoIds: [],
    sort: 13,
    commercialSort: 12,
    isFocused: false
  }, {
    id: 'st-andrews',
    name: 'St. Andrews at Deer Lake',
    location: 'DEER LAKE',
    address: '',
    description: 'This landmark project encompassing apartments, townhomes and commercial space received a special award from the BC Buildings Corporation for setting the tone for the prestigious Oaklands Community in Burnaby.',
    completionDate: 'Completed | ????',
    type: 'COMMERCIAL',
    //    isCommercial: 'true',
    projectCategories: [3],
    portfolioImage: '/assets/images/st-andrews/portfolio@1x.jpg',
    portfolioImageWidth: '141',
    portfolioImageHeight: '114',
    portfolioImageMinWidth: '53',
    portfolioSpacer: 'w-510/1000',
    commercialImage: '/assets/images/st-andrews/commercial@1x.jpg',
    commercialImageWidth: '114',
    commercialImageHeight: '96',
    commercialImageMinWidth: '53',
    commercialSpacer: 'w-510/1000',
    cardMargins: '',
    photoIds: [],
    sort: 13,
    commercialSort: 11,
    isFocused: false
  }, {
    id: 'province-building',
    name: 'The Province Building',
    location: 'VANCOUVER',
    address: '198 West Hastings Street, Vancouver',
    description: 'Office space that is refreshingly different from traditional commercial towers. The restoration of this 1908 heritage building received an Award for Excellence from the Urban Development Institute.',
    completionDate: 'Completed | 1998',
    type: 'RESTORATION',
    comercialType: 'COMMERCIAL',
    pageLayout: 'layout-province',
    featureImage: '/assets/images/province-building/feature@1x.jpg',
    featureImageWidth: '180',
    featureImageHeight: '121',
    detailTitle: 'HISTORIC INTERIORS ENHANCED WITH CONTEMPORARY SYSTEMS',
    detailCopyOne: 'The Province Building’s 42,000 square feet of beautifully restored office space blends high ceilings, ornate plasterwork and marble cladding with contemporary lighting, telecommunications, security and office planning.',
    detailCopyTwo: 'The award-winning project is a pioneering effort in the restoration of pride and liveability to the Victory Square area, the original urban and cultural heart of Vancouver.',
    detailCopyThree: 'Today, the building offers an uplifting environment, refreshingly different from the traditional office towers.',
    detailCopyFour: 'The process involved peeling away decades of neglect to uncover the essence of the building’s exterior and interior.',
    isAll: 'true',
    isCommercial: 'true',
    isRestoration: 'true',
    projectCategories: [3, 5],
    portfolioImage: '/assets/images/province-building/portfolio@1x.jpg',
    portfolioImageWidth: '134',
    portfolioImageHeight: '99',
    portfolioImageMinWidth: '53',
    portfolioSpacer: 'w-510/1000',
    commercialImage: '/assets/images/province-building/commercial@1x.jpg',
    commercialImageWidth: '122',
    commercialImageHeight: '137',
    commercialImageMinWidth: '53',
    commercialSpacer: 'w-510/1000',
    restorationImage: '/assets/images/province-building/restoration@1x.jpg',
    restorationImageWidth: '102',
    restorationImageHeight: '147',
    restorationSpacer: 'w-635/1000',
    cardMargins: '',
    photoIds: [],
    sort: 13,
    commercialSort: 15,
    isFocused: false
  }];
  _exports.default = _default;
});