define("m-corp/routes/components/cross-fade/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AwE+8EGz",
    "block": "{\"symbols\":[\"@project\"],\"statements\":[[10,\"div\"],[14,0,\"bg-red flex w-full h-full\"],[12],[2,\"\\n\"],[6,[37,2],[[32,1,[\"isFocused\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,1],null,[[\"use\",\"initialInsertion\",\"finalRemoval\",\"duration\"],[[35,0],true,true,400]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"img\"],[14,0,\"object-cover\"],[15,\"alt\",[32,1,[\"name\"]]],[14,\"src\",\"/assets/images/triomphe/hero.jpg\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"   \"],[10,\"img\"],[14,0,\"object-cover\"],[15,\"alt\",[32,1,[\"name\"]]],[15,\"src\",[32,1,[\"portfolioImage\"]]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"focus\",\"animated-value\",\"if\"]}",
    "meta": {
      "moduleName": "m-corp/routes/components/cross-fade/template.hbs"
    }
  });

  _exports.default = _default;
});