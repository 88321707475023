define("m-corp/routes/components/about-aside/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MIj0gbCb",
    "block": "{\"symbols\":[\"&attrs\",\"@title\",\"@body\",\"@project\"],\"statements\":[[8,\"animated-container\",[[24,0,\"flex\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[30,[36,0],[\"description\",[32,4,[\"id\"]]],null]],[[\"initialInsertion\",\"finalRemoval\",\"duration\",\"use\"],[true,true,1000,[32,0,[\"copyTransition\"]]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[17,1],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex flex-col text-gray-900 text-center\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"font-poynter text-3xl pb-2\"],[12],[2,\"\\n        \"],[1,[32,2]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"font-poynter text-sm leading-relaxed\"],[12],[2,\"\\n        \"],[1,[32,3]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"w-10 m-auto mt-4 border-b-2 border-gold\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"animated-value\"]}",
    "meta": {
      "moduleName": "m-corp/routes/components/about-aside/template.hbs"
    }
  });

  _exports.default = _default;
});