define("m-corp/routes/components/about-aside/component", ["exports", "@glimmer/component", "ember-animated/motions/opacity", "ember-animated"], function (_exports, _component, _opacity, _emberAnimated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AboutAsideComponent extends _component.default {
    *copyTransition({
      duration,
      insertedSprites,
      removedSprites,
      sentSprites,
      receivedSprites
    }) {
      for (let sprite of removedSprites) {
        (0, _opacity.fadeOut)(sprite, {
          duration: duration * .25
        });
      }

      ;

      for (let sprite of sentSprites) {
        sprite.applyStyles({
          'z-index': '40'
        });
        (0, _opacity.fadeOut)(sprite, {
          duration: duration * .25
        });
      }

      ;

      for (let sprite of receivedSprites) {
        sprite.moveToFinalPosition();
      }

      yield (0, _emberAnimated.wait)(duration * .5);

      for (let sprite of insertedSprites) {
        sprite.applyStyles({
          'z-index': '400'
        });
        (0, _opacity.fadeIn)(sprite, {
          duration: duration * .5
        });
      }

      ;

      for (let sprite of receivedSprites) {
        sprite.applyStyles({
          'z-index': '40'
        });
        new _opacity.Opacity(sprite, {
          from: 0,
          to: 1,
          duration: duration * .5
        }).run(); //  fadeIn uses sprite.initialComputedStyle which in this case is 1
        //  fadeIn(sprite, {duration: duration * 0.5});
        //  creatae app/motions/opacityFromTo.js as per
        //  https://github.com/ember-animation/ember-animated/issues/218
        //  https://github.com/chrism/ember-animated-container
      }

      ;
    }

  }

  _exports.default = AboutAsideComponent;
});