define("m-corp/routes/components/io/image-stack/component", ["exports", "@glimmer/component", "ember-animated/motions/opacity", "d3-ease", "ember-animated/motions/move", "ember-animated/motions/resize"], function (_exports, _component, _opacity, _d3Ease, _move, _resize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const inViewport = (e, {
    top: t,
    height: h
  } = e.getBoundingClientRect()) => t <= innerHeight && t + h >= 0;

  let IoImageStackComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = (_temp = class IoImageStackComponent extends _component.default {
    constructor() {
      super(...arguments); // console.log('ImageStack constructor');
      // this.router.on('routeWillChange', (transition) => {
      //   console.log('imageStack routeWillChange for: ', this.args.project.id);
      //   this.addDirectionToSprites(transition);
      // });
      // this.router.on('routeDidChange', (transition) => {
      //   console.log('imageStack routeDidChange for: ', this.args.project.id);
      //   this.addDirectionToSprites(transition);
      // });
      // routeWillChange fires for outgoing sprites
      // scheduleOnce('afterRender', this, 'updateIncomingAnimationIds' );
      //      (concat @project.id image.section)

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "routeAnimation", _descriptor2, this);

      _initializerDefineProperty(this, "viewportManager", _descriptor3, this);

      _initializerDefineProperty(this, "isInViewport", _descriptor4, this);

      _initializerDefineProperty(this, "dynamicAnimationId", _descriptor5, this);
    }

    didInsertBoo(element) {
      if (inViewport(element)) {
        // console.log('did-insert inViewport');
        this.isInViewport = true;
        this.viewportManager.addElementToViewport(this.args.project.id);
        this.dynamicAnimationId = this.viewportManager.animationIdForIncomingElement(this.args.project.id);
      }
    } // addDirectionToSprites(transition){
    //   console.log('addDirectionToSprite');
    //   // examing the to and from.
    //   // if this section is the same as this transition.to then data-sprite=sent
    //   // if
    // }
    //
    // on routeWillChange update using viewportManager
    // animationIdforElement(this.args.project.id);
    // on afterRender will update incoming ids
    // updateIncomingAnimationIds() {
    // }
    // updateOutgoingAnimationIds() {
    //   this.dynamicAnimationId = this.viewportManager.animationIdForOutgoingElement(this.args.project.id);
    // }
    //


    get animationId() {
      // if(this.routeAnimation.isDynamic){
      //   console.log('imageStack animation is dynamic');
      //   return this.dynamicAnimationId;
      // }
      // else {
      //   console.log('imageStack animation is static');
      return `${this.args.project.id}${this.args.section}`; // }
    }

    get isLazyLoader() {
      var _this$args$isLazyLoad;

      return (_this$args$isLazyLoad = this.args.isLazyLoading) !== null && _this$args$isLazyLoad !== void 0 ? _this$args$isLazyLoad : true;
    } // these fire last in the stack


    inDoor() {
      // console.log('onEnter ', this.args.project.id, ' is in view');
      this.viewportManager.addElementToViewport(this.args.project.id);
      this.isInViewport = true;
    }

    outDoor() {
      // console.log('onExit', this.args.project.id, 'is out of view');
      this.viewportManager.removeElementFromViewport(this.args.project.id);
      this.isInViewport = false;
    }

    get isProject() {
      if (this.args.section === 'project') {
        return true;
      } else {
        return false;
      }
    }

    portfolioImageForSection(section) {
      let sectionImageAttribute = `${section}Image`;
      let sectionImageUrl = this.args.project.get(sectionImageAttribute);

      if (sectionImageUrl) {
        return sectionImageUrl;
      } else {
        return this.args.project.portfolioImage;
      }
    }

    get imageStack() {
      let stack = [];

      if (this.args.project.isFeatured) {
        stack.push({
          section: 'featured',
          src: this.portfolioImageForSection('featured')
        });
      }

      if (this.args.project.isMasterPlanned) {
        stack.push({
          section: 'masterplan',
          src: this.portfolioImageForSection('masterplan')
        });
      }

      if (this.args.project.isHospitality) {
        stack.push({
          section: 'hospitality',
          src: this.portfolioImageForSection('hospitality')
        });
      }

      if (this.args.project.isCommercial) {
        stack.push({
          section: 'commercial',
          src: this.portfolioImageForSection('commercial')
        });
      }

      if (this.args.project.isResidential) {
        stack.push({
          section: 'residential',
          src: this.portfolioImageForSection('residential')
        });
      }

      if (this.args.project.isRestoration) {
        stack.push({
          section: 'restoration',
          src: this.portfolioImageForSection('restoration')
        });
      }

      if (this.args.project.isAll) {
        stack.push({
          section: 'all',
          src: this.args.project.portfolioImage
        });
      } // if(this.args.project.isAbout){
      //   stack.push( {section: 'about', src: this.portfolioImageForSection('about') });
      // }


      return stack;
    }

    conditionalTransition() {
      if (Ember.isPresent(this.args.section) && this.args.section === "project") {
        // console.log('transitionFromProject');
        this.transition(...arguments);
      } else {
        // console.log('transitionToProjectt');
        this.transition(...arguments);
      }
    } //(concat @project.id image.section)


    *transition({
      duration,
      keptSprites,
      sentSprites,
      receivedSprites,
      insertedSprites,
      removedSprites
    }) {
      //   console.log('imageStack transition: ', arguments[0]);
      // use the opacity of the sprite to discard
      // the items that do not need animating
      // ignore the ones that are transparent both ways
      // initial and finalComputeStyles.opacity
      //
      // Just adjusted inserted and removed and it
      // negatively affected project transitions
      // removing the illustion of layers
      // if we are heading to a project the removed sprites
      // should be delayed before removal.
      // if we are heating from a project to a portfolio
      // the inserted sprites should be immediate
      // along with the project card copy
      //
      // to fix this we will need to use the data-
      // to set data-insert-delay data-remove-delay accordingly.
      for (let sprite of insertedSprites) {
        if (sprite.finalComputedStyle.opacity > 0) {
          // console.log('ImageStack inserted visible');
          sprite.applyStyles({
            'z-index': '20'
          });
          (0, _opacity.fadeIn)(sprite, {
            duration: duration
          });
        }
      }

      for (let sprite of removedSprites) {
        if (sprite.initialComputedStyle.opacity > 0) {
          sprite.applyStyles({
            'z-index': '20'
          });
          (0, _opacity.fadeOut)(sprite, {
            duration: duration
          });
        }
      } // landing -> landing
      // This should be decided once
      // dynamicAnimationId needs to take this into account
      // shift to a get and computed value based on something
      // set by the route or the controller before the transition
      // that would be easy activiate perhaps even passing in
      // isAnimationIdDynamic to this component
      //
      // we can evaluate the transition.to/from and label
      // the destination side.
      // that is what project really is marking
      // send from project


      for (let sprite of sentSprites) {
        if (sprite.element.dataset.direction === 'project') {
          // console.log('sending from project');
          sprite.applyStyles({
            'z-index': '50'
          });
          (0, _resize.default)(sprite, {
            easing: _d3Ease.easeExpInOut
          });
          (0, _move.default)(sprite, {
            easing: _d3Ease.easeExpInOut
          });

          if (sprite.finalComputedStyle.opacity > 0) {
            (0, _opacity.fadeIn)(sprite, {
              duration: duration / 1
            });
          } else {
            (0, _opacity.fadeOut)(sprite, {
              duration: duration / 1
            });
          }
        } // the dataset.direciton was to tweak the animation so
        // it was always the project side that was animation the
        // send and receives.
        //

      } // received to project


      for (let sprite of receivedSprites) {
        if (sprite.element.dataset.direction === 'project') {
          // console.log('receiving to project');
          sprite.applyStyles({
            'z-index': '50'
          });
          (0, _resize.default)(sprite, {
            easing: _d3Ease.easeExpInOut
          });
          (0, _move.default)(sprite, {
            easing: _d3Ease.easeExpInOut
          });

          if (sprite.finalComputedStyle.opacity > 0) {
            (0, _opacity.fadeIn)(sprite, {
              duration: duration / 1
            });
          } else {
            (0, _opacity.fadeOut)(sprite, {
              duration: duration / 1
            });
          }
        }
      } //

    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "routeAnimation", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "viewportManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isInViewport", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "dynamicAnimationId", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'default to the (concat in the template';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "didInsertBoo", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "didInsertBoo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "inDoor", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "inDoor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "outDoor", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "outDoor"), _class.prototype)), _class));
  _exports.default = IoImageStackComponent;
});