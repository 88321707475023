define("m-corp/routes/components/io/mutating-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jMWNRYnW",
    "block": "{\"symbols\":[\"@staged\",\"@project\",\"@stage\",\"@portfolio\",\"@height\",\"@width\"],\"statements\":[[8,\"animated-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[11,\"div\"],[16,0,[31,[\"relative flex items-center\\n     \",[32,6],\"\\n     \",[32,5]]]],[16,5,[32,0,[\"maintainAspectRatio\"]]],[4,[38,2],[[32,0,[\"initialHeight\"]]],null],[12],[2,\"\\n\\n\"],[6,[37,4],[[30,[36,3],[[32,2,[\"id\"]],\"portfolio\"],null]],[[\"initialInsertion\",\"duration\",\"use\"],[true,1000,[32,0,[\"portfolioElement\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"img\"],[15,0,[31,[\"absolute object-cover w-full h-full \",[30,[36,1],[[32,1],\"opacity-0\"],null]]]],[15,\"alt\",[32,2,[\"name\"]]],[15,\"src\",[32,4]],[12],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[32,2,[\"id\"]],\"detail\"],null]],[[\"initialInsertion\",\"duration\",\"use\"],[true,1000,[32,0,[\"detailElement\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"img\"],[15,0,[31,[\"absolute object-cover w-full h-full \",[30,[36,0],[[32,1],\"opacity-0\"],null]]]],[15,\"alt\",[32,2,[\"name\"]]],[15,\"src\",[32,3]],[12],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"unless\",\"if\",\"did-insert\",\"concat\",\"animated-value\"]}",
    "meta": {
      "moduleName": "m-corp/routes/components/io/mutating-image/template.hbs"
    }
  });

  _exports.default = _default;
});