define("m-corp/routes/components/io/past-project/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cmmG5bGH",
    "block": "{\"symbols\":[\"@project\"],\"statements\":[[10,\"div\"],[14,0,\"flex flex-col\"],[12],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n  \"],[10,\"img\"],[14,0,\"xl:max-w-79\"],[15,\"alt\",[32,1,[\"name\"]]],[15,\"src\",[32,1,[\"portfolioImage\"]]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hidden md:block pt-4 px-6 pb-2\\n           text-center w-full z-100\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"gold-gradient text-xs tracking-widest\"],[12],[2,\"\\n      \"],[1,[32,1,[\"type\"]]],[2,\" | \"],[1,[32,1,[\"location\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"font-poynter py-2 text-2xl\\n                lg:text-3xl leading-none\"],[12],[2,\"\\n      \"],[1,[32,1,[\"name\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"font-poynter py-2\"],[12],[2,\"\\n      \"],[1,[32,1,[\"address\"]]],[10,\"br\"],[12],[13],[2,\"\\n      Completed | \"],[1,[32,1,[\"completionDate\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "m-corp/routes/components/io/past-project/template.hbs"
    }
  });

  _exports.default = _default;
});