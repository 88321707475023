define("m-corp/routes/project/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ProjectRoute extends Ember.Route {
    model(params) {
      return this.store.findRecord('project', params.project_id);
    }

  }

  _exports.default = ProjectRoute;
});