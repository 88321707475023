define("m-corp/routes/components/io/hero-headline/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fk2nfG5Q",
    "block": "{\"symbols\":[\"@one\",\"@two\",\"@project\"],\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,2],[[32,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,3,[\"id\"]],\"hero.bg\"],null]],[[\"initialInsertion\",\"finalRemoval\",\"duration\",\"use\"],[true,true,1000,[32,0,[\"transition\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"hidden md:block absolute top-0 right-0 w-74 lg:w-64\\n           justify-center\\n           text-center font-poynter leading-none pink-gradient\\n           -mt-4\\n           mx-12\\n           lg:mr-20 xl:mr-47\"],[12],[2,\"\\n  \"],[10,\"span\"],[12],[10,\"span\"],[14,0,\"text-xl\"],[12],[1,[32,1]],[13],[13],[10,\"br\"],[12],[13],[2,\"\\n  \"],[10,\"span\"],[12],[1,[32,2]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"animated-value\",\"is-empty\",\"not\",\"if\"]}",
    "meta": {
      "moduleName": "m-corp/routes/components/io/hero-headline/template.hbs"
    }
  });

  _exports.default = _default;
});