define("m-corp/routes/components/io/animated-morph/component", ["exports", "@glimmer/component", "ember-animated/motions/opacity", "ember-animated/motions/move", "ember-animated/motions/resize", "d3-ease", "ember-animated/-private/sprite"], function (_exports, _component, _opacity, _move, _resize, _d3Ease, _sprite) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const inViewport = (e, {
    top: t,
    height: h
  } = e.getBoundingClientRect()) => t <= innerHeight && t + h >= 0;

  let IoAnimatedMorphComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = (_temp = class IoAnimatedMorphComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "viewportManager", _descriptor, this);

      _initializerDefineProperty(this, "routeAnimation", _descriptor2, this);

      _defineProperty(this, "spriteId", 'sprite' + Ember.guidFor(this).substring(5));

      _initializerDefineProperty(this, "isInViewport", _descriptor3, this);

      _initializerDefineProperty(this, "viewportIndex", _descriptor4, this);

      this.transition = this.transition.bind(this);
    }

    didInsertBoo(element) {
      // console.log('tracking didInsert AI with id: ', this.spriteId)
      this.viewportManager.addDestinationPageElement(this.spriteId);

      if (inViewport(element)) {
        this.isInViewport = true;
        this.viewportManager.addElement(this.spriteId); //this.viewportIndex = this.viewportManager.indexOfIncomingSprite(this.spriteId);
        //this.dynamicAnimationId = this.viewportManager.animationIdForIncomingElement(this.spriteId);
        // later(this, function () {
        //   this.viewportManager.lastViewportImages.pushObject("apples");
        // }, 15000);
      }
    }

    inDoor() {
      this.viewportManager.addElement(this.spriteId);
      this.isInViewport = true;
    }

    outDoor() {
      this.viewportManager.removeElement(this.spriteId);
      this.isInViewport = false;
    }

    *transition({
      duration,
      keptSprites,
      sentSprites,
      receivedSprites,
      insertedSprites,
      removedSprites
    }) {
      //  console.log('route animation isDynamic: ', this.routeAnimation.isDynamic);
      insertedSprites.forEach(sprite => {
        let targetId;
        let target;

        if (Ember.isPresent(this.args.forcedMatch) && !this.routeAnimation.isDynamic) {
          target = document.getElementById('orphans').querySelector(`[data-match="${this.args.forcedMatch}"]`);
        } else {
          targetId = this.viewportManager.proximityMatchFor(this.spriteId);
          target = document.getElementById(targetId);
        }

        if (Ember.isPresent(target) && this.routeAnimation.isDynamic) {
          let targetSprite = new _sprite.default(target, true);
          sprite.startAtSprite(targetSprite);
          sprite.applyStyles({
            'z-index': '200',
            'max-width': 'none'
          });
          (0, _resize.default)(sprite, {
            easing: _d3Ease.easeExpInOut
          });
          (0, _move.default)(sprite, {
            easing: _d3Ease.easeExpInOut
          });
          new _opacity.Opacity(sprite, {
            from: 0,
            to: 1,
            duration: duration * 1
          }).run();
        } else {
          if (this.routeAnimation.isDynamic) {
            if (sprite.absoluteFinalBounds.left < window.innerWidth - sprite.absoluteFinalBounds.right) {
              sprite.startAtPixel({
                x: -sprite.absoluteFinalBounds.width
              });
              (0, _move.default)(sprite, {
                easing: _d3Ease.easeExpInOut,
                duration: duration * 1
              });
              (0, _opacity.fadeIn)(sprite);
            } else {
              sprite.startAtPixel({
                x: window.innerWidth + 0
              });
              (0, _move.default)(sprite, {
                easing: _d3Ease.easeExpInOut,
                duration: duration * 1
              });
              (0, _opacity.fadeIn)(sprite);
            }
          } else {
            sprite.applyStyles({
              'z-index': 'unset'
            });
            (0, _opacity.fadeIn)(sprite);
          }
        }
      });

      for (let sprite of removedSprites) {
        // console.log('removingSprite: ', this.spriteId);
        // console.log('removed: ', this.removed);
        // if(this.removed){
        //   continue;
        // }
        // this.removed = true;
        let targetId;
        let target;

        if (Ember.isPresent(this.args.forcedMatch) && !this.routeAnimation.isDynamic) {
          target = document.querySelector(`[data-match="${this.args.forcedMatch}"]`);
        } else {
          targetId = this.viewportManager.proximityMatchFor(this.spriteId);

          if (Ember.isPresent(targetId)) {
            target = document.getElementById(targetId);
          }
        }

        if (Ember.isPresent(target) && this.routeAnimation.isDynamic) {
          // console.log('match for for removed: ', this.spriteId, targetId);
          let targetSprite = new _sprite.default(target);
          sprite.endAtSprite(targetSprite);
          sprite.applyStyles({
            'z-index': '100',
            'max-width': 'none'
          });
          (0, _resize.default)(sprite, {
            easing: _d3Ease.easeExpInOut
          });
          (0, _move.default)(sprite, {
            easing: _d3Ease.easeExpInOut
          });
          (0, _opacity.fadeOut)(sprite);
        } else {
          if (this.routeAnimation.isDynamic) {
            // if the route transition is dynamic to one of these
            if (sprite.initialBounds.left < window.innerWidth - sprite.initialBounds.right) {
              sprite.endAtPixel({
                x: -sprite.initialBounds.width
              });
              (0, _move.default)(sprite, {
                easing: _d3Ease.easeExpInOut,
                duration: duration * 1
              });
              (0, _opacity.fadeOut)(sprite, {
                duration: duration * 0.35
              });
            } else {
              sprite.endAtPixel({
                x: window.innerWidth + 0
              });
              (0, _move.default)(sprite, {
                easing: _d3Ease.easeExpInOut,
                duration: duration * 1
              });
              (0, _opacity.fadeOut)(sprite, {
                duration: duration * 0.35
              });
            }
          } else {
            // if the route transition is not dynamic
            // leave these in place, fade to .25 and scale to the opposite of the hover
            sprite.applyStyles({
              'z-index': 'unset'
            });
            (0, _opacity.fadeOut)(sprite);
          }
        }
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "viewportManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "routeAnimation", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isInViewport", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "viewportIndex", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "didInsertBoo", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "didInsertBoo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "inDoor", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "inDoor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "outDoor", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "outDoor"), _class.prototype)), _class));
  _exports.default = IoAnimatedMorphComponent;
});