define("m-corp/routes/components/io/card-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MZ7cIesF",
    "block": "{\"symbols\":[\"@project\",\"@stage\",\"@portfolio\"],\"statements\":[[8,\"animated-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[11,\"div\"],[24,0,\"relative flex items-center xd-hover:opacity-0\"],[4,[38,0],[[32,0,[\"initialHeight\"]]],null],[12],[2,\"\\n\\n\"],[6,[37,2],[[30,[36,1],[[32,1,[\"id\"]],\"portfolio\"],null]],[[\"initialInsertion\",\"duration\",\"use\"],[true,1000,[32,0,[\"cardElement\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"img\"],[14,0,\"w-full xd-h-full xd-absolute xd-object-cover\"],[15,\"alt\",[32,1,[\"name\"]]],[15,\"src\",[32,3]],[12],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,1,[\"id\"]],\"detail\"],null]],[[\"duration\",\"use\"],[1000,[32,0,[\"detailElement\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"img\"],[14,0,\"absolute object-cover w-full h-full opacity-0\"],[15,\"alt\",[32,1,[\"name\"]]],[15,\"src\",[32,2]],[12],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"concat\",\"animated-value\"]}",
    "meta": {
      "moduleName": "m-corp/routes/components/io/card-image/template.hbs"
    }
  });

  _exports.default = _default;
});