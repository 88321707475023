define("m-corp/routes/components/io/ghost-image/component", ["exports", "@glimmer/component", "ember-animated/motions/opacity", "ember-animated/motions/move", "ember-animated/motions/resize"], function (_exports, _component, _opacity, _move, _resize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IoGhostImageComponent extends _component.default {
    *imageTransition({
      receivedSprites,
      sentSprites
    }) {
      // console.log("GhostImage image: ", arguments[0]);
      receivedSprites.forEach(sprite => {
        sprite.applyStyles({
          'z-index': '20'
        });
        (0, _resize.default)(sprite);
        (0, _move.default)(sprite);
        (0, _opacity.fadeIn)(sprite);
      });
      sentSprites.forEach(sprite => {
        sprite.applyStyles({
          'z-index': '20'
        });
        (0, _resize.default)(sprite);
        (0, _move.default)(sprite);
        (0, _opacity.fadeOut)(sprite);
      });
    }

  }

  _exports.default = IoGhostImageComponent;
});