define("m-corp/components/validated-input/-themes/uikit/error", ["exports", "ember-validated-form/components/validated-input/-themes/uikit/error"], function (_exports, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _error.default;
    }
  });
});