define("m-corp/routes/components/care-hero/component", ["exports", "@glimmer/component", "ember-animated/motions/opacity"], function (_exports, _component, _opacity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CareHeroComponent extends _component.default {
    *imageTransition({
      duration,
      insertedSprites,
      receivedSprites,
      removedSprites
    }) {
      // console.log("care hero: ", arguments[0]);
      insertedSprites.forEach(sprite => {
        // console.log("care hero fade in");
        (0, _opacity.fadeIn)(sprite, {
          duration: duration * .5
        });
      });
      removedSprites.forEach(sprite => {
        // console.log("care hero fade out");
        (0, _opacity.fadeOut)(sprite, {
          duration: duration * .5
        });
      });
    }

  }

  _exports.default = CareHeroComponent;
});