define("m-corp/routes/components/portfolio-menu/component", ["exports", "@glimmer/component", "ember-animated", "ember-animated/motions/move", "ember-animated/motions/opacity"], function (_exports, _component, _emberAnimated, _move, _opacity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PortfolioMenuComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = (_temp = class PortfolioMenuComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "appState", _descriptor2, this);

      _initializerDefineProperty(this, "menuItems", _descriptor3, this);

      this.transition = this.transition.bind(this);
    }

    get inPortfolio() {
      if (this.args.currentRouteName.includes('portfolio')) {
        return true;
      } else {
        return false;
      }
    }

    get isProject() {
      if (this.args.currentRouteName === 'portfolio.project') {
        return true;
      } else {
        return false;
      }
    }

    *screenTransition({
      insertedSprites,
      removedSprites
    }) {
      for (let sprite of insertedSprites) {
        sprite.applyStyles({
          'opacity': '0'
        }); // console.log('screenTransition');

        yield (0, _emberAnimated.wait)(550);
        (0, _opacity.fadeIn)(sprite);
      }
    }

    *transition({
      duration,
      keptSprites,
      insertedSprites,
      removedSprites
    }) {
      for (let sprite of keptSprites) {
        (0, _move.default)(sprite);
      }

      for (let sprite of insertedSprites) {
        (0, _opacity.fadeOut)(sprite, {
          duration: 0
        });
      }

      if (insertedSprites.length > 0) {
        let delay = insertedSprites[0].element.dataset.delay;
        yield (0, _emberAnimated.wait)(delay);
      }

      let sortedInserts;

      if (this.args.isDesktop) {
        sortedInserts = insertedSprites.reverse();
      } else {
        sortedInserts = insertedSprites;
      }

      for (let sprite of sortedInserts) {
        if (insertedSprites.indexOf(sprite) === 0) {
          let menuScreen = document.querySelector('#portfolio-menu-screen');
          menuScreen.style.opacity = 1;
        } // This talks about using observers to know when items are animating
        // https://github.com/ember-animation/ember-animated/issues/132
        // explore that when there is time


        sprite.applyStyles({
          'z-index': `${(insertedSprites.length - insertedSprites.indexOf(sprite)) * 100}`
        });
        sprite.startAtPixel({
          y: -110
        });
        (0, _opacity.fadeIn)(sprite, {
          duration: 0
        });
        (0, _move.default)(sprite);
        yield (0, _emberAnimated.wait)(33);

        if (insertedSprites.indexOf(sprite) === insertedSprites.length - 1) {
          let menuScreen = document.querySelector('#portfolio-menu-screen');
          yield (0, _emberAnimated.wait)(300);
          menuScreen.style.opacity = 0;
        }
      }

      for (let sprite of removedSprites) {
        if (removedSprites.indexOf(sprite) === 0) {
          let menuScreen = document.querySelector('#portfolio-menu-screen');
          menuScreen.style.opacity = 1;
        }

        sprite.applyStyles({
          'z-index': `${(removedSprites.length - removedSprites.indexOf(sprite)) * 100}`
        });
        sprite.endAtPixel({
          y: 0
        });
        (0, _move.default)(sprite, {
          duration: duration * 1
        });
        yield (0, _emberAnimated.wait)(33);

        if (removedSprites.indexOf(sprite) === removedSprites.length - 1) {
          let menuScreen = document.querySelector('#portfolio-menu-screen');
          yield (0, _emberAnimated.wait)(300);
          menuScreen.style.opacity = 0;
        }
      }
    }

    startMenu() {// console.log('startmenu');
      // this. menuItems= A(['Featured', 'Master-planned', 'Hospitality', 'Commercial', 'Residential Rental', 'Restoration', 'Paris, France', 'All']);
    }

    transitionToParentRoute() {
      if (Ember.isPresent(this.appState.parentPortfolioRoute)) {
        this.router.transitionTo(this.appState.parentPortfolioRoute);
      } else {
        // update the menu timieto invlude the proper route name
        // to remogb ehte need fo r all theise if
        this.navigateTo(this.args.menuItems.firstObject.name);
      }
    }

    navigateTo(route) {
      if (route === 'All') {
        this.router.transitionTo('portfolio');
      } else if (route === 'Residential Rental') {
        this.router.transitionTo('portfolio.residential');
      } else if (route === 'Paris, France') {
        this.router.transitionTo('portfolio.paris');
      } else {
        this.router.transitionTo(`portfolio.${route.toLowerCase()}`);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "appState", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "menuItems", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "startMenu", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "startMenu"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transitionToParentRoute", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToParentRoute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "navigateTo", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "navigateTo"), _class.prototype)), _class));
  _exports.default = PortfolioMenuComponent;
});