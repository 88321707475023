define("m-corp/routes/components/site-footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JXsO4qhK",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[17,1],[24,0,\"flex flex-row flex-shrink-0 items-center text-gray-600 w-full max-w-page md:h-26 lg:h-30\"],[12],[2,\"\\n\\n  \"],[8,\"link-to\",[],[[\"@route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[10,\"img\"],[14,0,\"mx-6 lg:mx-14 w-11\"],[14,\"src\",\"/assets/images/footer-logo.png\"],[14,\"alt\",\"millennium\"],[12],[13]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"flex text-xs h-17 items-end font-brandon\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"hidden lg:block text-xs\"],[12],[2,\"\\n      \"],[10,\"h3\"],[12],[2,\"Millennium Group\"],[13],[2,\"\\n      \"],[10,\"p\"],[12],[2,\"788 Richards Street, Vancouver BC V6B 3A4 Canada\"],[13],[2,\"\\n      \"],[10,\"p\"],[12],[2,\"T (604) 688-2300  F (604) 683-3420\"],[13],[2,\"\\n      \"],[10,\"p\"],[12],[2,\"hq@millenniumdevelopment.com\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"flex flex-grow\"],[12],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"flex flex-row items-end h-17\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"block text-xs text-left font-brandon\"],[12],[2,\"\\n      © 2021 MILLENNIUM DEVELOPMENT\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex\"],[12],[2,\"\\n      \"],[10,\"a\"],[14,\"target\",\"_blank\"],[14,6,\"https://www.instagram.com/millennium_development/\"],[12],[2,\"\\n      \"],[10,\"img\"],[14,0,\"ml-5 lg:mr-14 mr-6\"],[14,\"src\",\"/assets/images/insta.png\"],[14,\"alt\",\"instagram\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "m-corp/routes/components/site-footer/template.hbs"
    }
  });

  _exports.default = _default;
});