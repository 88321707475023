define("m-corp/routes/components/portfolio-screen/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tnhUwORy",
    "block": "{\"symbols\":[\"@opacity\",\"@project\"],\"statements\":[[6,[37,1],[[30,[36,0],[[32,2,[\"id\"]],\"screen\"],null]],[[\"initialInsertion\",\"finalRemoval\",\"duration\",\"use\"],[true,true,1000,[32,0,[\"transition\"]]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"absolute h-full w-full portfolio-screen-gradient opacity-\",[32,1]]]],[12],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"animated-value\"]}",
    "meta": {
      "moduleName": "m-corp/routes/components/portfolio-screen/template.hbs"
    }
  });

  _exports.default = _default;
});