define("m-corp/routes/components/layout-landscape/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XNFdvkRm",
    "block": "{\"symbols\":[\"@margin\",\"@project\"],\"statements\":[[2,\"    \"],[11,\"div\"],[16,0,[31,[\"flex flex-grow\\n                mt-6 z-10 lg:mt-16\\n                \",[30,[36,0],[[32,1],\"lg:ml-39 lg:mr-18 xl:mx-39\"],null]]]],[4,[38,1],null,[[\"onEnter\",\"onExit\"],[[32,0,[\"hideSectionTitle\"]],[32,0,[\"showSectionTitle\"]]]]],[12],[2,\"\\n\\n     \"],[10,\"img\"],[14,0,\"max-full self-center\"],[14,\"alt\",\"self-center\"],[15,\"src\",[31,[\"/assets/images/\",[32,2,[\"id\"]],\"/5@1x.jpg\"]]],[12],[13],[2,\"\\n    \"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"in-viewport\"]}",
    "meta": {
      "moduleName": "m-corp/routes/components/layout-landscape/template.hbs"
    }
  });

  _exports.default = _default;
});