define("m-corp/routes/components/io/hero-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BZczSsyu",
    "block": "{\"symbols\":[\"@height\",\"@width\",\"@project\",\"@src\"],\"statements\":[[8,\"animated-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,3,[\"id\"]],\"image\"],null]],[[\"initialInsertion\",\"duration\",\"use\"],[true,1000,[32,0,[\"imageTransition\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[\"flex items-center\\n                \",[32,2],\"\\n                \",[32,1]]]],[12],[2,\"\\n      \"],[10,\"img\"],[14,0,\"object-cover w-full h-full\"],[15,\"alt\",[32,3,[\"name\"]]],[15,\"src\",[32,4]],[12],[13],[2,\"\\n\\n     \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"animated-value\"]}",
    "meta": {
      "moduleName": "m-corp/routes/components/io/hero-image/template.hbs"
    }
  });

  _exports.default = _default;
});