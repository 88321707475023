define("m-corp/helpers/html-safe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function htmlSafe(params
  /*, hash*/
  ) {
    return htmlSafe(params[0]);
  });

  _exports.default = _default;
});