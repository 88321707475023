define("m-corp/helpers/spacer-for-project-in-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function spacerForProjectInSection([project, section]) {
    let targetedSpacer = Ember.isPresent(project.get(`${section}Spacer`)) ? project.get(`${section}Spacer`) : project.portfolioSpacer;
    return targetedSpacer;
  });

  _exports.default = _default;
});