define("m-corp/routes/components/io/animated-morph/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5vBkqLEr",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,\"animated-container\",[[4,[38,0],[[32,0,[\"didInsertBoo\"]]],null],[4,[38,1],null,[[\"onEnter\",\"onExit\"],[[32,0,[\"inDoor\"]],[32,0,[\"outDoor\"]]]]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,0,[\"spriteId\"]]],[[\"initialInsertion\",\"finalRemoval\",\"duration\",\"use\"],[true,true,1000,[32,0,[\"transition\"]]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[15,1,[32,0,[\"spriteId\"]]],[14,0,\"w-full h-full overflow-hidden\"],[12],[2,\"\\n          \"],[18,1,[[32,0,[\"spriteId\"]]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"in-viewport\",\"animated-value\"]}",
    "meta": {
      "moduleName": "m-corp/routes/components/io/animated-morph/template.hbs"
    }
  });

  _exports.default = _default;
});