define("m-corp/routes/components/io/animated-images/component", ["exports", "@glimmer/component", "ember-animated/motions/opacity", "d3-ease", "ember-animated/motions/move", "ember-animated/motions/resize"], function (_exports, _component, _opacity, _d3Ease, _move, _resize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IoAnimatedImagesComponent extends _component.default {
    *transition({
      duration,
      keptSprites,
      sentSprites,
      receivedSprites,
      insertedSprites,
      removedSprites
    }) {
      // send from project
      for (let sprite of sentSprites) {
        if (sprite.element.dataset.role === 'placeholder') {
          // console.log('sending from project');
          sprite.applyStyles({
            'z-index': '50'
          });
          (0, _resize.default)(sprite, {
            easing: _d3Ease.easeExpInOut
          });
          (0, _move.default)(sprite, {
            easing: _d3Ease.easeExpInOut
          });

          if (sprite.finalComputedStyle.opacity > 0) {
            (0, _opacity.fadeIn)(sprite, {
              duration: duration / 1
            });
          } else {
            (0, _opacity.fadeOut)(sprite, {
              duration: duration / 1
            });
          }
        } // the dataset.direciton was to tweak the animation so
        // it was always the project side that was animating the
        // send and receives. ie the target
        //
        // we shuould be able to monito the sourbe/target
        // using the appstate service {{aninmator.location (source/target)}}
        // orientaion position ani-id ani-role sprite-id sprite-role anim-id anim-role
        // sprite-id sprite-role, role animator/placeholer vs source/target
        //
        // if we can animate the sender out and receiver in
        // we can animate without caring about having the stack
        // on both ends
        //
        // so the @role needs to change from a static placeholder
        // to the originator of the image
        //
        // or
        //
        // we could set the inserted sprite to the same position of the removed sprite
        // and vice versa, givining them inserted initialBounds and removed finalBounds
        // of added and removed sprites.
        //
        // I like the idea using added and removed since that is what the are
        // viewportManager provides the index positioon of images
        // on willTransition dimension and position are updated
        // on did-insert (?) we share the dimension of the incoming
        //
        //
        // use transition objects to mark sender vs receiver
        // if this.route matches from.route then wer are the sender
        //
        // essentially this letting us dynamivally setup sender and receiver
        //
        // j

      } // received to project


      for (let sprite of receivedSprites) {
        if (sprite.element.dataset.role === 'animator') {
          // console.log('receiving to project');
          sprite.applyStyles({
            'z-index': '50'
          });
          (0, _resize.default)(sprite, {
            easing: _d3Ease.easeExpInOut
          });
          (0, _move.default)(sprite, {
            easing: _d3Ease.easeExpInOut
          });

          if (sprite.finalComputedStyle.opacity > 0) {
            (0, _opacity.fadeIn)(sprite, {
              duration: duration / 1
            });
          } else {
            (0, _opacity.fadeOut)(sprite, {
              duration: duration / 1
            });
          }
        }
      }

      insertedSprites.forEach(sprite => {
        sprite.applyStyles({
          'z-index': '1'
        });
        sprite.startAtPixel({
          x: window.innerWidth + 200
        });
        (0, _move.default)(sprite, {
          easing: _d3Ease.easeExpInOut
        });
      });
      removedSprites.forEach(sprite => {
        sprite.applyStyles({
          'z-index': '1'
        });
        sprite.endAtPixel({
          x: window.innerWidth + 200
        });
        (0, _move.default)(sprite, {
          easing: _d3Ease.easeExpInOut,
          duration: duration * 1
        });
      });
    }

  }

  _exports.default = IoAnimatedImagesComponent;
});