define("m-corp/routes/components/io/mutating-image/component", ["exports", "@glimmer/component", "ember-animated/motions/opacity", "d3-ease", "ember-animated/motions/move", "ember-animated/motions/resize"], function (_exports, _component, _opacity, _d3Ease, _move, _resize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let IoMutatingImageComponent = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = (_temp = class IoMutatingImageComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "height", _descriptor, this);
    }

    get maintainAspectRatio() {
      let safeStyle = '';
      safeStyle = `height: ${this.height}px;`;
      return Ember.String.htmlSafe(safeStyle);
    }

    initialHeight(element) {
      let ratio = this.args.height / this.args.width;
      this.height = element.offsetWidth * ratio;
    }

    adjustHeight(element) {
      //console.log('cardImage checking animation status');
      if (!this.args.isAnimating) {
        //console.log('cardImage adjusting Height');
        let ratio = this.args.height / this.args.width;
        this.height = element.offsetWidth * ratio;
      }
    }

    get minWidth() {
      return this.args.width * 0.4;
    }

    *portfolioElement({
      duration,
      insertedSprites,
      receivedSprites,
      sentSprites
    }) {
      // console.log("portfolioElement transition: ", arguments[0]);
      for (let sprite of insertedSprites) {
        (0, _opacity.fadeIn)(sprite);
      }

      for (let sprite of receivedSprites) {
        //      console.log('card-image received:', sprite);
        //sprite.applyStyles({
        //  'z-index': '20',
        //});
        (0, _resize.default)(sprite, {
          easing: _d3Ease.easeExpInOut
        });
        (0, _move.default)(sprite, {
          easing: _d3Ease.easeExpInOut
        });
        (0, _opacity.fadeIn)(sprite, {
          duration: duration / 2
        });
      }

      for (let sprite of sentSprites) {
        // console.log('card-image sent:', sprite);
        // sprite.applyStyles({
        //   'z-index': '20'
        // });
        (0, _resize.default)(sprite, {
          easing: _d3Ease.easeExpInOut
        });
        (0, _move.default)(sprite, {
          easing: _d3Ease.easeExpInOut
        });
        (0, _opacity.fadeOut)(sprite, {
          duration: duration / 2
        });
      }
    }

    *detailElement({
      duration,
      insertedSprites,
      receivedSprites,
      sentSprites
    }) {
      // console.log("detailElement transition: ", arguments[0]);
      for (let sprite of insertedSprites) {//   fadeIn(sprite);
      }

      for (let sprite of sentSprites) {
        // console.log('hero-image sent:', sprite);
        // sprite.applyStyles({
        //   'z-index': '30'
        // });
        (0, _resize.default)(sprite, {
          easing: _d3Ease.easeExpInOut
        });
        (0, _move.default)(sprite, {
          easing: _d3Ease.easeExpInOut
        });
        (0, _opacity.fadeOut)(sprite, {
          duration: duration / 2
        });
      }

      for (let sprite of receivedSprites) {
        // console.log('hero-image received:', sprite);
        // sprite.applyStyles({
        //   'z-index': '1',
        // });
        (0, _resize.default)(sprite, {
          easing: _d3Ease.easeExpInOut
        });
        (0, _move.default)(sprite, {
          easing: _d3Ease.easeExpInOut
        });
        (0, _opacity.fadeIn)(sprite, {
          duration: duration / 2
        });
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "height", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "initialHeight", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "initialHeight"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "adjustHeight", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "adjustHeight"), _class.prototype)), _class));
  _exports.default = IoMutatingImageComponent;
});