define("m-corp/routes/components/project-card/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProjectCardComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = (_temp = class ProjectCardComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "media", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);
    }

    get cardMargins() {
      return this.router.currentRouteName === 'portfolio.commercial' ? '' : this.router.currentRouteName === 'portfolio.projects' ? '' : this.args.project.cardMargins;
    }

    get portfolioImage() {
      let targetedImageKey = `${this.args.section}Image`;
      let targetedImageUrl = this.args.project.get(targetedImageKey);

      if (targetedImageUrl) {
        return targetedImageUrl;
      } else {
        return this.args.project.portfolioImage;
      }
    }

    get imageWidth() {
      let hasSection = Ember.isPresent(this.args.project.get(`${this.args.section}ImageWidth`)) ? this.args.section : 'portfolio';
      let hasMobile = this.media.isTablet && Ember.isPresent(this.args.project.get(`${this.args.section}MobileImageWidth`)) ? 'Mobile' : '';
      let targetedWidth = `${hasSection}${hasMobile}ImageWidth`;
      return this.args.project.get(targetedWidth);
    }

    get imageHeight() {
      let hasSection = Ember.isPresent(this.args.project.get(`${this.args.section}ImageHeight`)) ? this.args.section : 'portfolio';
      let hasMobile = this.media.isTablet && Ember.isPresent(this.args.project.get(`${hasSection}MobileImageHeight`)) ? 'Mobile' : '';
      let targetedHeight = `${hasSection}${hasMobile}ImageHeight`;
      return this.args.project.get(targetedHeight);
    }

    get cardMaxWidth() {
      return Number(this.imageWidth) + 8;
    }

    get cardMinWidth() {
      return Number.parseFloat(this.imageWidth * 0.4).toFixed(2) + 8;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "media", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ProjectCardComponent;
});