define("m-corp/routes/components/portfolio-card/component", ["exports", "@glimmer/component", "ember-animated/motions/move", "ember-animated/motions/resize", "ember-animated/motions/opacity", "ember-animated"], function (_exports, _component, _move, _resize, _opacity, _emberAnimated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let PortfolioCardComponent = (_dec = Ember._action, _dec2 = Ember._action, (_class = class PortfolioCardComponent extends _component.default {
    get pseudo() {
      return `.${this.args.project.id}:after{ content: ''; display: block; padding-bottom:${this.args.project.aspectPadding};}`;
    }

    get isCardStaged() {
      return this.args.project.isFocused; // more like stagedItem-card
    }

    get isBlurred() {
      let blurred = !this.args.project.isFocused;
      return blurred;
    }

    *cardTransition({
      duration,
      receivedSprites,
      insertedSprites,
      removedSprites,
      sentSprites
    }) {
      // console.log("PortfolioCard card: ", arguments[0]);
      // receivedSprites.forEach(sprite => {
      //   sprite.applyStyles({
      //     'z-index': '1'
      //   });
      //   resize(sprite);
      //   move(sprite);
      // });
      // sentSprites.forEach(sprite => {
      //   move(sprite);
      //   resize(sprite);
      // });
      removedSprites.forEach(sprite => {
        (0, _opacity.fadeOut)(sprite, {
          duration: duration / 2
        });
      });
      yield (0, _emberAnimated.wait)(duration / 2);
      insertedSprites.forEach(sprite => {
        (0, _opacity.fadeIn)(sprite, {
          duration: duration / 2
        });
      });
    }

    *imageTransition({
      duration,
      keptSprites,
      receivedSprites,
      insertedSprites,
      removedSprites,
      sentSprites
    }) {
      //  console.log("PortfolioCard image: ", arguments[0]);
      receivedSprites.forEach(sprite => {
        sprite.applyStyles({
          'z-index': '20'
        });
        (0, _move.default)(sprite);
        (0, _resize.default)(sprite);
        (0, _opacity.fadeIn)(sprite);
      });
      sentSprites.forEach(sprite => {
        sprite.applyStyles({
          'z-index': '20'
        });
        (0, _resize.default)(sprite);
        (0, _move.default)(sprite);
        (0, _opacity.fadeOut)(sprite);
      }); // removedSprites.forEach(sprite => {
      //   fadeOut(sprite, { duration: duration / 2 });
      // });
    }

    *copyTransition({
      duration,
      keptSprites,
      receivedSprites,
      insertedSprites,
      removedSprites
    }) {
      // console.log("copyTransition: ", arguments[0]);
      removedSprites.forEach(sprite => {
        // sprite.applyStyles({
        //   'z-index': '100'
        // });
        (0, _opacity.fadeOut)(sprite, {
          duration: duration / 2
        });
      });
      yield (0, _emberAnimated.wait)(duration / 2);
      insertedSprites.forEach(sprite => {
        // sprite.applyStyles({
        //   'z-index': '100'
        // });
        (0, _opacity.fadeIn)(sprite, {
          duration: duration / 2
        });
      });
    }

    focus(project) {
      project.isFocused = true;
    }

    stageProject(project) {
      project.isFocused = true;
      this.args.stageProject(project);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "focus", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "focus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "stageProject", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "stageProject"), _class.prototype)), _class));
  _exports.default = PortfolioCardComponent;
});