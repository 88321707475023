define("m-corp/routes/portfolio/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PortfolioRoute extends Ember.Route {// model() {
    //   return this.store.findAll('project');
    //   // multi find
    // }
  }

  _exports.default = PortfolioRoute;
});