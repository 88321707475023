define("m-corp/routes/components/page-bg/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0B1zAfyZ",
    "block": "{\"symbols\":[\"@banner\",\"@image\"],\"statements\":[[10,\"img\"],[14,0,\"absolute inset-0 w-screen pointer-events-none\"],[15,\"src\",[31,[\"/assets/images/\",[32,2]]]],[14,\"alt\",\"background\"],[12],[13],[2,\"\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"img\"],[14,0,\"absolute w-screen mt-78 pointer-events-none\"],[15,\"src\",[31,[\"/assets/images/\",[32,1]]]],[14,\"alt\",\"banner\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "m-corp/routes/components/page-bg/template.hbs"
    }
  });

  _exports.default = _default;
});