define("m-corp/routes/portfolio/projects/more/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PortfolioProjectsMoreRoute extends Ember.Route {
    model() {
      return this.store.findAll('past'); // multi find
    }

  }

  _exports.default = PortfolioProjectsMoreRoute;
});