define("m-corp/routes/portfolio/project/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ke/OQWmY",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"fixed h-full w-full max-w-page overflow-scroll pb-53\"],[12],[2,\"\\n\"],[8,\"portfolio-stage\",[],[[\"@project\",\"@unstageProject\",\"@isAnimating\"],[[32,0,[\"model\"]],[32,0,[\"unstageProject\"]],[32,0,[\"isAnimating\"]]]],null],[2,\"\\n\"],[8,\"site-footer\",[[24,0,\"absolute z-10 mt-12 pb-12\"]],[[],[]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "m-corp/routes/portfolio/project/template.hbs"
    }
  });

  _exports.default = _default;
});