define("m-corp/routes/components/video-feature/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RVvtlxxk",
    "block": "{\"symbols\":[\"&attrs\",\"@aspectRatio\",\"@cover\",\"@poster\",\"@video\"],\"statements\":[[11,\"div\"],[17,1],[24,0,\"absolute max-w-full h-auto\\n            overflow-hidden\\n            bg-black\"],[4,[38,0],null,[[\"--aspect-ratio\"],[[32,2]]]],[12],[2,\"\\n\"],[11,\"video\"],[16,0,[31,[\"transition \",[30,[36,1],[[32,3],\"object-cover\"],null]]]],[24,\"controls\",\"\"],[24,\"onloadedmetadata\",\"this.muted=true\"],[24,\"playsinline\",\"\"],[24,\"autoplay\",\"autoplay\"],[24,\"muted\",\"\"],[16,\"poster\",[32,4]],[24,\"preload\",\"auto\"],[4,[38,2],[[32,0,[\"initVideo\"]]],null],[12],[2,\"\\n  \"],[10,\"source\"],[15,\"src\",[32,5]],[14,4,\"video/mp4\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"style\",\"if\",\"did-insert\"]}",
    "meta": {
      "moduleName": "m-corp/routes/components/video-feature/template.hbs"
    }
  });

  _exports.default = _default;
});