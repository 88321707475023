define("m-corp/routes/components/hero-tile/component", ["exports", "@glimmer/component", "ember-animated/motions/opacity", "ember-animated/motions/move", "ember-animated/motions/resize", "d3-ease"], function (_exports, _component, _opacity, _move, _resize, _d3Ease) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class HeroTileComponent extends _component.default {
    *imageTransition({
      duration,
      insertedSprites,
      receivedSprites,
      sentSprites,
      removedSprites
    }) {
      //  console.log("HeroTile image: ", arguments[0]);
      receivedSprites.forEach(sprite => {
        sprite.applyStyles({
          'z-index': '20'
        });
        (0, _resize.default)(sprite, {
          easing: _d3Ease.easeExpInOut
        });
        (0, _move.default)(sprite, {
          easing: _d3Ease.easeExpInOut
        });
        (0, _opacity.fadeIn)(sprite, {
          easing: _d3Ease.easeExpInOut
        });
      });
      sentSprites.forEach(sprite => {
        sprite.applyStyles({
          'z-index': '10'
        });
        (0, _resize.default)(sprite, {
          easing: _d3Ease.easeExpInOut
        });
        (0, _move.default)(sprite, {
          easing: _d3Ease.easeExpInOut
        });
        (0, _opacity.fadeOut)(sprite, {
          easing: _d3Ease.easeExpInOut
        });
      });
      insertedSprites.forEach(sprite => {
        sprite.applyStyles({
          'z-index': '1'
        });
        sprite.startAtPixel({
          x: window.innerWidth + 200
        });
        (0, _move.default)(sprite, {
          easing: _d3Ease.easeExpInOut
        });
      });
      removedSprites.forEach(sprite => {
        sprite.applyStyles({
          'z-index': '1'
        });
        sprite.endAtPixel({
          x: window.innerWidth + 200
        });
        (0, _move.default)(sprite, {
          easing: _d3Ease.easeExpInOut,
          duration: duration * 1
        });
      });
    }

  }

  _exports.default = HeroTileComponent;
});