define("m-corp/routes/components/io/card-image/component", ["exports", "@glimmer/component", "ember-animated/motions/opacity", "d3-ease", "ember-animated/motions/move", "ember-animated/motions/resize"], function (_exports, _component, _opacity, _d3Ease, _move, _resize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let IoCardImageComponent = (_dec = Ember._action, (_class = class IoCardImageComponent extends _component.default {
    initialHeight(element) {
      let ratio = this.args.height / this.args.width;
      let height = element.offsetWidth * ratio;
      element.style.height = `${height}px`;
    }

    get minWidth() {
      return this.args.width * 0.4;
    }

    *cardElement({
      duration,
      keptSprites,
      insertedSprites,
      receivedSprites,
      sentSprites
    }) {
      // console.log("portfolioElement transition: ", arguments[0]);
      for (let sprite of keptSprites) {//      resize(sprite, {easing: easeExpInOut});
        //      move(sprite, {easing: easeExpInOut});
      }

      for (let sprite of insertedSprites) {
        (0, _opacity.fadeIn)(sprite);
      }

      for (let sprite of receivedSprites) {//      console.log('card-image received:', sprite);
        // sprite.applyStyles({
        //   'opacity': '0',
        // });
        // resize(sprite, {easing: easeExpInOut});
        // move(sprite, {easing: easeExpInOut});
        // fadeIn(sprite, {duration: duration / 2});
      }

      for (let sprite of sentSprites) {
        // console.log('card-image sent:', sprite);
        // sprite.applyStyles({
        //   'z-index': '20'
        // });
        (0, _resize.default)(sprite, {
          easing: _d3Ease.easeExpInOut
        });
        (0, _move.default)(sprite, {
          easing: _d3Ease.easeExpInOut
        }); // fadeOut(sprite, {duration: duration / 2} );
      }
    }

    *detailElement({
      duration,
      insertedSprites,
      receivedSprites,
      sentSprites
    }) {
      // console.log("detailElement transition: ", arguments[0]);
      for (let sprite of insertedSprites) {
        (0, _opacity.fadeIn)(sprite);
      }

      for (let sprite of sentSprites) {// console.log('hero-image sent:', sprite);
        // sprite.applyStyles({
        //   'z-index': '30'
        // });
        // resize(sprite, {easing: easeExpInOut});
        // move(sprite, {easing: easeExpInOut});
        // fadeOut(sprite, {duration: duration / 2});
      }

      for (let sprite of receivedSprites) {// console.log('hero-image received:', sprite);
        // sprite.applyStyles({
        //   'z-index': '1',
        // });
        // resize(sprite, {easing: easeExpInOut});
        // move(sprite, {easing: easeExpInOut});
        // fadeIn(sprite, {duration: duration / 2});
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "initialHeight", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "initialHeight"), _class.prototype)), _class));
  _exports.default = IoCardImageComponent;
});