define("m-corp/routes/components/io/animated-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Dtqw5Llh",
    "block": "{\"symbols\":[\"@forcedMatch\",\"@src\"],\"statements\":[[8,\"animated-container\",[[4,[38,0],[[32,0,[\"didInsertBoo\"]]],null],[4,[38,1],null,[[\"onEnter\",\"onExit\"],[[32,0,[\"inDoor\"]],[32,0,[\"outDoor\"]]]]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n\"],[6,[37,2],[[32,0,[\"spriteId\"]]],[[\"initialInsertion\",\"finalRemoval\",\"duration\",\"use\"],[true,true,1000,[32,0,[\"transition\"]]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"img\"],[15,1,[32,0,[\"spriteId\"]]],[14,0,\"absolute z-20 top-0 object-cover w-full h-full\"],[15,\"data-match\",[32,1]],[14,\"alt\",\"alt name\"],[15,\"src\",[32,2]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"in-viewport\",\"animated-value\"]}",
    "meta": {
      "moduleName": "m-corp/routes/components/io/animated-image/template.hbs"
    }
  });

  _exports.default = _default;
});