define("m-corp/routes/components/io/project-card/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let IoProjectCardComponent = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = (_temp = class IoProjectCardComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "height", _descriptor, this);
    }

    get maintainAspectRatio() {
      let safeStyle = '';
      safeStyle = `height: ${this.height}px;`;
      return Ember.String.htmlSafe(safeStyle);
    } //


    adjustAspectRatio(element) {//   let card = document.getElementById(this.args.project.id);
      //    let aspectRatio = `${card.offsetWidth}/${card.offsetHeight}`;
      //    element.style.setProperty('--aspect-ratio', aspectRatio);
      //    element.style.setProperty('position', 'absolute');

      /*
       * if we are going to measure the copyHeight, why not just measure
       * project card.  also after a debounced resize.
       */
    }

    adjustHeight(element) {
      let ratio = this.args.height / this.args.width;
      this.height = Math.round(element.offsetWidth * ratio + 240);
    }

    *cardTransition({}) {// this is animated from its StageCard sibling
      // console.log("ProjectCard: ", arguments[0]);
      // receivedSprites.forEach(sprite => {
      //   console.log('ProjectCard animating received');
      //   sprite.applyStyles({
      //     'z-index': '1'
      //   });
      //   move(sprite);
      //   resize(sprite);
      // });
      // sentSprites.forEach(sprite => {
      //   console.log('ProjectCard animating sent');
      //    sprite.applyStyles({
      //     'z-index': '1'
      //   });
      //   move(sprite);
      //   resize(sprite);
      // });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "height", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 20;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "adjustAspectRatio", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "adjustAspectRatio"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "adjustHeight", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "adjustHeight"), _class.prototype)), _class));
  _exports.default = IoProjectCardComponent;
});