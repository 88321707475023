define("m-corp/routes/components/hero-tile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7UGpbjpZ",
    "block": "{\"symbols\":[\"@width\",\"@aspectRatio\",\"@project\",\"@src\",\"@animationId\"],\"statements\":[[8,\"animated-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,5]],[[\"initialInsertion\",\"finalRemoval\",\"duration\",\"use\"],[true,true,1000,[32,0,[\"imageTransition\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[\"flex\\n               \",[32,1]]]],[15,5,[31,[\"--aspect-ratio:\",[32,2]]]],[12],[2,\"\\n      \"],[10,\"img\"],[14,0,\"object-cover h-full w-full\"],[15,\"alt\",[32,3,[\"name\"]]],[15,\"src\",[32,4]],[12],[13],[2,\"\\n     \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"animated-value\"]}",
    "meta": {
      "moduleName": "m-corp/routes/components/hero-tile/template.hbs"
    }
  });

  _exports.default = _default;
});