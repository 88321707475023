define("m-corp/routes/components/project-hero/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A4c2udXR",
    "block": "{\"symbols\":[\"value\",\"@project\"],\"statements\":[[10,\"div\"],[14,0,\"flex flex-row relative mt-45 ml-36 mb-33\"],[12],[2,\"\\n\\n  \"],[8,\"animated-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,2]],[[\"initialInsertion\",\"duration\",\"use\"],[true,1000,[32,0,[\"transition\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"bg-gray-100\"],[12],[2,\"\\n      \"],[11,\"button\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"blur\"]],[32,1]],null]],null],[12],[2,\"\\n      \"],[10,\"img\"],[14,0,\"z-10\"],[14,\"alt\",\"project\"],[15,\"src\",[32,1,[\"portfolioImage\"]]],[12],[13],[2,\"  \"],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n\"]],\"parameters\":[1]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"w-64 ml-29 text-center font-roboto font-bold\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"\"],[12],[2,\"\\n      now\"],[10,\"br\"],[12],[13],[2,\"selling\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"w-full\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"text-xs\"],[12],[2,\"RESIDENTIAL | BRENTWOOD\"],[13],[2,\"\\n      \"],[10,\"h3\"],[14,0,\"text-3xl pb-2\"],[12],[1,[32,2,[\"name\"]]],[13],[2,\"\\n      \"],[10,\"p\"],[14,0,\"pb-4 text-xs text-center\"],[12],[2,\"\\n        Positioned at the architectural gateway to Brentwood,\\n        a vibrant urban neighbourhood. World-class shopping and\\n        entertainment destinations are just minutes from Triomphe.\"],[13],[2,\"\\n      \"],[10,\"p\"],[14,0,\"text-xs pb-2\"],[12],[2,\"1880 Gilmore St, Burnaby\"],[10,\"br\"],[12],[13],[2,\"Expected Completion  |  2020\"],[13],[2,\"\\n      \"],[10,\"button\"],[12],[2,\"FIND OUT MORE\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"flex-grow\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"animated-value\"]}",
    "meta": {
      "moduleName": "m-corp/routes/components/project-hero/template.hbs"
    }
  });

  _exports.default = _default;
});