define("m-corp/routes/components/feature-slider/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3d6nyJIk",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[24,0,\"relative max-w-282 overflow-hidden z-10 opacity-0\"],[4,[38,0],null,[[\"--aspect-ratio\"],[\"1128/617\"]]],[4,[38,1],null,[[\"enterClass\",\"enterActiveClass\",\"enterToClass\",\"leaveClass\",\"leaveActiveClass\",\"leaveToClass\"],[\"opacity-0\",\"transition-all duration-100 delay-1000\",\"opacity-100\",\"opacity-100\",\"transition-all duration-100 delay-1000\",\"opacity-0\"]]],[12],[2,\"\\n  \"],[8,\"swiper-container\",[],[[\"@options\"],[[32,0,[\"options\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"swiper-slide\",[],[[],[]],[[\"default\"],[{\"statements\":[[10,\"img\"],[14,\"src\",\"/assets/images/featured-slide-4@1x.jpg\"],[14,\"alt\",\"some\"],[12],[13]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"swiper-slide\",[],[[],[]],[[\"default\"],[{\"statements\":[[10,\"img\"],[14,\"src\",\"/assets/images/featured-slide-3@1x.jpg\"],[14,\"alt\",\"some\"],[12],[13]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"swiper-slide\",[],[[],[]],[[\"default\"],[{\"statements\":[[10,\"img\"],[14,\"src\",\"/assets/images/featured-slide-2@1x.jpg\"],[14,\"alt\",\"some\"],[12],[13]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"swiper-slide\",[],[[],[]],[[\"default\"],[{\"statements\":[[10,\"img\"],[14,\"src\",\"/assets/images/featured-slide-1@1x.jpg\"],[14,\"alt\",\"some\"],[12],[13]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"style\",\"css-transition\"]}",
    "meta": {
      "moduleName": "m-corp/routes/components/feature-slider/template.hbs"
    }
  });

  _exports.default = _default;
});