define("m-corp/helpers/visibility-for-image-in-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function visibilityForImageInSection([imageSection, section]) {
    if (imageSection === section) {
      return 'opacity-100';
    } else {
      return 'opacity-0';
    }
  });

  _exports.default = _default;
});