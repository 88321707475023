define("m-corp/services/viewport-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const inViewport = (e, {
    top: t,
    height: h
  } = e.getBoundingClientRect()) => t <= innerHeight && t + h >= 0;

  let ViewportManagerService = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, (_class = (_temp = class ViewportManagerService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "viewportImages", _descriptor2, this);

      _initializerDefineProperty(this, "viewportPoints", _descriptor3, this);

      _initializerDefineProperty(this, "lastViewportImages", _descriptor4, this);

      _initializerDefineProperty(this, "lastViewportPoints", _descriptor5, this);

      _initializerDefineProperty(this, "currentElements", _descriptor6, this);

      _initializerDefineProperty(this, "previousElements", _descriptor7, this);

      _initializerDefineProperty(this, "proximityElements", _descriptor8, this);

      _initializerDefineProperty(this, "forcedMatches", _descriptor9, this);

      _initializerDefineProperty(this, "excludedForceMatchElement", _descriptor10, this);

      _defineProperty(this, "destinationPageElements", Ember.A([]));

      this.router.on('routeWillChange', transition => {
        this.updateTrackedElements();
        this.excludeForcedMatchElement(transition);
      });
    } // this needs to be renamed
    // more lke prepare for transition


    updateTrackedElements() {
      this.previousElements = Ember.A([]);

      for (let element of this.currentElements) {
        let xyUpdated = this.xyOfElement(element.id);
        xyUpdated.id = element.id;
        this.previousElements.addObject(xyUpdated);
      }

      this.currentElements = Ember.A([]);
      this.destinationPageElements = Ember.A([]);
      this.proximityMatches = null; // console.log('vm updatingTrackedElements on routeWillChange');
    }

    excludeForcedMatchElement(transition) {
      this.excludedForceMatchElement = null;

      if (Ember.isPresent(transition.from.params.project_id)) {
        this.excludedForceMatchElement = this.forcedMatches[transition.from.params.project_id];
      }

      if (Ember.isPresent(transition.to.params.project_id)) {
        this.excludedForceMatchElement = this.forcedMatches[transition.to.params.project_id];
      }

      this.forcedMatches = {};
    }

    xyOfElement(id) {
      let elementRect = document.getElementById(id).getBoundingClientRect();
      let xy = {
        x: elementRect.left,
        y: elementRect.top
      };
      return xy;
    } // remove after the next two are tested

    /*
    updatePositionOfCurrentElements() {
      for (let element of this.currentElements) {
        let xyUpdated = this.xyOfElement(element.id);
        element.x = xyUpdated.x;
        element.y = xyUpdated.y;
      }
    }
    */
    // These two methods update detination page viewports
    // to accomotate scroll-into-view.scrollTop [scroll(0,0)]


    addDestinationPageElement(id) {
      this.destinationPageElements.addObject(id);
    }

    updateDestinationViewportElements() {
      this.currentElements = Ember.A([]);

      for (let elementId of this.destinationPageElements) {
        let element = document.getElementById(elementId);

        if (inViewport(element)) {
          this.addElement(elementId);
        }
      }
    }

    addElement(id) {
      // console.log('vm is adding an element');
      let element = this.xyOfElement(id);
      element.id = id;

      if (!this.currentElements.findBy('id', id)) {
        this.currentElements.addObject(element);
      }
    }

    removeElement(id) {
      this.currentElements = this.currentElements.rejectBy('id', id);
    }

    setForcedMatchElementForId(spriteId, id) {
      this.forcedMatches[id] = spriteId;
    }
    /*
    yxSortElements() {
      this.currentElements = this.currentElements.sortBy('y', 'x');
      this.previousElements = this.previousElements.sortBy('y', 'x');
    }
    */


    proximityMatchFor(id) {
      // console.log('vm supplying proxmity match');
      let potentials = this.sortedByProximity();
      return potentials[id];
    }

    sortedByProximity() {
      let these;
      let those;

      if (Ember.isPresent(this.proximityMatches)) {
        return this.proximityMatches;
      } else {
        delete this.previousElements[this.excludedForceMatchElement];
        delete this.currentElements[this.excludedForceMatchElement];

        if (this.currentElements.length <= this.previousElements.length) {
          these = this.currentElements;
          those = this.previousElements;
        } else {
          these = this.previousElements;
          those = this.currentElements;
        }

        this.proximityMatches = {};

        for (let thisElement of these) {
          for (let thatElement of those) {
            let dx = thisElement.x - thatElement.x;
            let dy = thisElement.y - thatElement.y;
            let distance = Math.sqrt(dx * dx + dy * dy);
            thatElement.distance = distance;
          }

          let closest = those.sortBy('distance').objectAt(0);
          thisElement.closest = closest;
          this.proximityMatches[thisElement.id] = closest.id;
          this.proximityMatches[closest.id] = thisElement.id;
        }

        return this.proximityMatches; // we are going to look for matches in both directions
        // should I create keys both ways
        // { sprite1:sprite2, sprite2:sprite1}==="="=
        // this woudl allow use to do proximityMatchFor(id)
        // !! in either direction
        // this is done.
      }
    }
    /*
    insertedMatchForRemovedElement(id) {
      this.yxSortElements();
      let matchingIndex = this.previousElements.findIndex(elem => elem['id'] === id);
      if (matchingIndex === -1) {
        return null;
      } else {
        if ( this.currentElements.length > matchingIndex ) {
          return this.currentElements.objectAt(matchingIndex).id;
        } else {
          return null;
        }
      }
    }
     removedMatchForInsertedElement(id) {
      this.yxSortElements();
      let matchingIndex = this.currentElements.findIndex(elem => elem['id'] === id);
      if (matchingIndex === -1) {
        return null;
      } else {
        if ( this.previousElements.length > matchingIndex ) {
          return this.previousElements.objectAt(matchingIndex).id;
        } else {
          return null;
        }
      }
    }
    
    shiftViewportImages(){
      // console.log('trackingIndex: shifting viewportImages');
      this.lastViewportImages = this.viewportImages;
      this.viewportImages = A([]);
       this.viewportPoints = A([]);
      this.lastViewportPoints = A([]);
      for (let id of this.lastViewportImages) {
        this.lastViewportPoints.addObject(this.xyOfElement(id));
      }
      this.updateTrackedElements();
    }
     addElementToViewport(id) {
      // console.log('trackingIndex of: ', id);
      this.viewportImages.addObject(id);
      this.viewportPoints.addObject(this.xyOfElement(id));
      // console.log('trackingIndex: ', this.viewportImages);
      this.addElement(id);
    }
     removeElementFromViewport(id) {
      // console.log('removing element: ', id);
       //let index = this.viewportImages.indexOf(id);
       //this.viewportPoints.removeAt(index);
      this.viewportImages.removeObject(id);
      this.removeElement(id);
    }
     get sortedViewportImages() {
      return viewportImages.sort('x', 'y');
    }
     get sortedLastViewportImages() {
      return this.lastViewportImages.sort('x', 'y');
    }
     outgoingSpriteIdAtIndex(index) {
      // console.log('outgoing trackingIndex: ', index);
      return this.lastViewportImages.objectAt(index);
    }
     incomingSpriteIdAtIndex(index) {
      // console.log('incoming trackingIndex: ', index);
      return this.viewportImages.objectAt(index);
    }
     indexOfOutgoingSprite(id) {
      // console.log('outgoing trackingIndex requested for: ', id);
      return this.lastViewportImages.indexOf(id);
    }
     indexOfIncomingSprite(id) {
      // console.log('incoming trackingIndex requested for: ', id);
      return this.viewportImages.indexOf(id);
    }
     animationIdForOutgoingElement(id){
      // console.log('outgoing trackingIndex requested for: ', id);
      return this.viewportImages.indexOf(id);
    }
    animationIdForIncomingElement(id){
      // console.log('incoming trackingIndex requested for: ', id);
      return this.viewportImages.indexOf(id);
    }
    */
    // on will transition copy viewport images to previousViewportImages
    // then get domrects for each of them
    //
    // initialBoundsForElement(id)
    //
    // I am using the id so that I could retriege teh index/animationId
    // we do not need that anymore.
    //
    // we just need the domrect for the index
    //
    // initialBoundsForIndex(index)
    // finalBoundsForIndex(index)
    //
    // But we will need the id to find the element
    // element.getBoundingClientRect()
    // then startSpriteAtPixel
    // scale
    // along wth
    // endSpriteAtPixel
    // scale
    //
    // there is also startAtSprite that does the above
    // we could get the element in the transition and create
    // the Sprite
    // https://github.com/ember-animation/ember-animated/blob/41b54e791f08d3f444d3d11dd21b27839d3c14ab/tests/unit/motion-test.js#L34
    // https://github.com/ember-animation/ember-animated/blob/31c3bbc949e068c395f2a690d1158744127d71af/addon/components/animated-each.ts#L675
    //
    //  that is was send are receive do
    //  https://github.com/ember-animation/ember-animated/blob/31c3bbc949e068c395f2a690d1158744127d71af/addon/components/animated-each.ts#L675
    //
    //  using the motion service to get farMathes
    //  we are using position rather than preicate to make the match
    //  but perhaps we can try changing the id and send out receive in
    //  more easily, the draw back is that the sprite needs to exist on both sides
    //  vs just one.
    //
    //  we will need a way to disable this for some transitions
    //  - portfolio to project will only move target images
    //    can we use the same route-animation?


  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "viewportImages", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "viewportPoints", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "lastViewportImages", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "lastViewportPoints", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "currentElements", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "previousElements", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "proximityElements", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "forcedMatches", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "excludedForceMatchElement", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class));
  _exports.default = ViewportManagerService;
});