define("m-corp/routes/components/portfolio-screen/component", ["exports", "@glimmer/component", "ember-animated/motions/opacity", "d3-ease", "ember-animated/motions/move", "ember-animated/motions/resize"], function (_exports, _component, _opacity, _d3Ease, _move, _resize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PortfolioScreenComponent extends _component.default {
    *transition({
      duration,
      receivedSprites,
      sentSprites
    }) {
      receivedSprites.forEach(sprite => {
        sprite.applyStyles({
          'z-index': '30'
        });
        (0, _resize.default)(sprite, {
          easing: _d3Ease.easeExpInOut,
          duration: duration
        });
        (0, _move.default)(sprite, {
          easing: _d3Ease.easeExpInOut,
          duration: duration
        });

        if (sprite.finalComputedStyle.opacity > 0) {
          (0, _opacity.fadeIn)(sprite, {
            duration: duration / 1
          });
        } else {
          (0, _opacity.fadeOut)(sprite, {
            duration: duration / 1
          });
        }
      });
    }

  }

  _exports.default = PortfolioScreenComponent;
});