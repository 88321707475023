define("m-corp/routes/components/video-bg/component", ["exports", "@glimmer/component", "ember-animated/motions/opacity"], function (_exports, _component, _opacity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let VideoBgComponent = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = (_temp = class VideoBgComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "video", null);

      _defineProperty(this, "videoTitle", null);

      _initializerDefineProperty(this, "appState", _descriptor, this);
    }

    initVideo(video) {
      this.video = video;
      video.muted = true;
      video.oncanplaythrough = this.fadeVideoIn.bind(this);
      video.onloadedmetadata = this.onLoadedMetaData.bind(this);
      this.videoTitle = document.getElementById('video-title');
    }

    get isVideoVisible() {
      if (this.args.currentRouteName === "index") {
        return true;
      } else {
        return false;
      }
    }

    onLoadedMetaData() {
      Ember.debug('video loaded metadata');
    }

    fadeVideoIn() {
      if (this.appState.didRender) {
        this.video.classList.remove('opacity-0');
        this.videoTitle.classList.add('opacity-0');
      } else {
        Ember.run.later(this, function () {
          this.video.classList.remove('opacity-0');
        }, 2500);
        Ember.run.later(this, function () {
          this.videoTitle.classList.remove('opacity-0');
        }, 4000);
        Ember.run.later(this, function () {
          this.videoTitle.classList.add('opacity-0');
        }, 8500);
      }
    }

    *transition({
      duration,
      removedSprites
    }) {
      // console.log("video transition: ", arguments[0]);
      removedSprites.forEach(sprite => {
        sprite.applyStyles({
          'z-index': '100'
        });
        (0, _opacity.fadeOut)(sprite);
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appState", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "initVideo", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "initVideo"), _class.prototype)), _class));
  _exports.default = VideoBgComponent;
});