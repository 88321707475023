define("m-corp/routes/components/io/hero-bg/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CMYJslVk",
    "block": "{\"symbols\":[\"@background\",\"@project\"],\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,2],[[32,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,2,[\"id\"]],\"hero.bg\"],null]],[[\"initialInsertion\",\"finalRemoval\",\"duration\",\"use\"],[true,true,1000,[32,0,[\"transition\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[10,\"img\"],[14,0,\"absolute top-0 right-0 h-screen overflow-hidden pointer-events-none\"],[15,\"src\",[31,[\"/assets/images/backgrounds/\",[32,1]]]],[14,\"alt\",\"background\"],[12],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"animated-value\",\"is-empty\",\"not\",\"if\"]}",
    "meta": {
      "moduleName": "m-corp/routes/components/io/hero-bg/template.hbs"
    }
  });

  _exports.default = _default;
});