define("m-corp/mirage/fixtures/pasts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: '1',
    name: 'The Bristol at Hampton Place',
    type: 'RESIDENTIAL',
    location: 'VANCOUVER',
    address: '5735 Hampton Place',
    completionDate: '1995',
    portfolioImage: '/assets/images/past/bristol@1x.jpg',
    sort: '1'
  }, {
    id: '2',
    name: 'Brownstone',
    type: 'RESIDENTIAL',
    location: 'VANCOUVER',
    address: '2933-2957 Laurel St/902-932 W 13th ',
    completionDate: '2004',
    portfolioImage: '/assets/images/past/brownstone@1x.jpg',
    sort: '2'
  }, {
    id: '3',
    name: 'Carlton at the Club',
    type: 'RESIDENTIAL',
    location: 'NORTH VANCOUVER',
    address: '1327 E. Keith Road',
    completionDate: '1992',
    portfolioImage: '/assets/images/past/carlton-at-the-club@1x.jpg',
    sort: '3'
  }, {
    id: '4',
    name: 'Carlton on the Park',
    type: 'RESIDENTIAL',
    location: 'BURNABY',
    address: '4350 Beresford Street',
    completionDate: '1987',
    portfolioImage: '/assets/images/past/carlton-on-the-park@1x.jpg',
    sort: '4'
  }, {
    id: '5',
    name: 'The Savoy Carlton',
    type: 'RESIDENTIAL',
    location: 'BURNABY',
    address: '6888 Station Hill Drive',
    completionDate: '1991',
    portfolioImage: '/assets/images/past/savoy-carlton@1x.jpg',
    sort: '5'
  }, {
    id: '6',
    name: 'Belgravia',
    type: 'RESIDENTIAL',
    location: 'BURNABY',
    address: '6838 Station Hill Drive',
    completionDate: '1996',
    portfolioImage: '/assets/images/past/belgravia@1x.jpg',
    sort: '6'
  }, {
    id: '7',
    name: 'Claridges',
    type: 'RESIDENTIAL',
    location: 'BURNABY',
    address: '6837 Station Hill Drive',
    completionDate: '1999',
    portfolioImage: '/assets/images/past/claridges@1x.jpg',
    sort: '7'
  }, {
    id: '8',
    name: 'Mayfair Place',
    type: 'RESIDENTIAL',
    location: 'BURNABY',
    address: '7368 -7388 Sandborne Avenue',
    completionDate: '2002',
    portfolioImage: '/assets/images/past/mayfair@1x.jpg',
    sort: '8'
  }, {
    id: '9',
    name: 'Villa Jardin',
    type: 'RESIDENTIAL',
    location: 'BURNABY',
    address: '6833 Station Hill Drive',
    completionDate: '2004',
    portfolioImage: '/assets/images/past/villa-jardin@1x.jpg',
    sort: '9'
  }, {
    id: '10',
    name: 'One Park Lane',
    type: 'RESIDENTIAL',
    location: 'NORTH VANCOUVER',
    address: '170 West 1st St',
    completionDate: '2005',
    portfolioImage: '/assets/images/past/one-park-lane@1x.jpg',
    sort: '10'
  }, {
    id: '11',
    name: 'St Andrews at Deer Lake',
    type: 'RESIDENTIAL',
    location: 'BURNABY',
    address: '5262 Oakmount Crescent St',
    completionDate: '1995',
    portfolioImage: '/assets/images/past/st-andrews-at-deer-lake@1x.jpg',
    sort: '11'
  }, {
    id: '12',
    name: 'St James Gate',
    type: 'RESIDENTIAL',
    location: 'St James Gate',
    address: '2253 Welcher Ave',
    completionDate: '1994',
    portfolioImage: '/assets/images/past/st-james-gate@1x.jpg',
    sort: '12'
  }, {
    id: '13',
    name: 'One University Crescent',
    type: 'RESIDENTIAL',
    location: 'BURNABY',
    address: '9388 - 9398 University Crescent ',
    completionDate: '2005',
    portfolioImage: '/assets/images/past/one-university-cresent@1x.jpg',
    sort: '13'
  }, {
    id: '14',
    name: 'One Madison Avenue I',
    type: 'RESIDENTIAL',
    location: 'BURNABY',
    address: '2345 Madison Ave',
    completionDate: '2007',
    portfolioImage: '/assets/images/past/one-madison-avenue-i@1x.jpg',
    sort: '14'
  }, {
    id: '15',
    name: 'Windsor Square Shopping Centre',
    type: 'COMMERCIAL',
    location: 'WHITE ROCK',
    address: '1959 152 Street',
    completionDate: '1987',
    portfolioImage: '/assets/images/past/windsor-square@1x.jpg',
    sort: '15'
  }, {
    id: '16',
    name: 'The Province Building',
    type: 'COMMERCIAL',
    location: 'VANCOUVER',
    address: '198 West Hastings St',
    completionDate: '1998',
    portfolioImage: '/assets/images/past/province-building@1x.jpg',
    sort: '16'
  }, {
    id: '17',
    name: 'Carrall',
    type: 'COMMERCIAL',
    location: 'VANCOUVER',
    address: '325 Carrall St',
    completionDate: '2021',
    portfolioImage: '/assets/images/past/carrall@1x.jpg',
    sort: '17'
  }, {
    id: '18',
    name: 'Carneby',
    type: 'RENTAL',
    location: 'NORTH VANCOUVER',
    address: '1215 St. Georges Ave',
    completionDate: '2019',
    portfolioImage: '/assets/images/past/carneby@1x.jpg',
    sort: '18'
  }, {
    id: '19',
    name: 'Sunwood Square Shopping Centre',
    type: 'COMMERCIAL',
    location: 'COQUITLAM',
    address: '3025 Lougheed Hwy',
    completionDate: '1990',
    portfolioImage: '/assets/images/past/sunwood-square@1x.jpg',
    sort: '19'
  }, {
    id: '20',
    name: 'Regency Place',
    type: 'RESIDENTIAL',
    location: 'WEST VANCOUVER',
    address: '2408 Haywood Ave',
    completionDate: '1984',
    portfolioImage: '/assets/images/past/regency@1x.jpg',
    sort: '20'
  }, {
    id: '21',
    name: 'Sunshine Village Shopping Centre',
    type: 'COMMERCIAL',
    location: 'DELTA',
    address: '6345 - 120 Street',
    completionDate: '1990',
    portfolioImage: '/assets/images/past/sunshine-village@1x.jpg',
    sort: '21'
  }];
  _exports.default = _default;
});