define("m-corp/routes/components/io/card-copy/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/sVerdxk",
    "block": "{\"symbols\":[\"@section\",\"@project\"],\"statements\":[[8,\"animated-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[\"description\",[32,2,[\"id\"]]],null]],[[\"initialInsertion\",\"duration\",\"use\"],[true,1250,[32,0,[\"copyTransition\"]]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[[30,[36,1],[[30,[36,0],[[32,2,[\"id\"]],\"hermitage\"],null],\"hidden\"],null],\"\\n                \",[30,[36,1],[[30,[36,0],[[32,1],\"hospitality\"],null],\"hidden\"],null],\"\\n                md:block\\n                w-full pt-4 px-6 pb-2 text-center z-100\"]]],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"hidden md:block gold-gradient text-xs tracking-widest\"],[12],[2,\"\\n          \"],[1,[32,2,[\"type\"]]],[2,\" | \"],[1,[32,2,[\"location\"]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"font-poynter py-2 text-2xl lg:text-3xl leading-none\"],[12],[2,\"\\n          \"],[1,[32,2,[\"name\"]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"hidden md:flex w-full md:flex-grow px-4 max-w-95 m-auto font-poynter detail-body\"],[12],[2,\" \"],[2,\"\\n          \"],[1,[32,2,[\"description\"]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"not-eq\",\"if\",\"concat\",\"animated-value\"]}",
    "meta": {
      "moduleName": "m-corp/routes/components/io/card-copy/template.hbs"
    }
  });

  _exports.default = _default;
});