define("m-corp/routes/components/portfolio-item/component", ["exports", "@glimmer/component", "ember-animated/motions/opacity", "ember-animated"], function (_exports, _component, _opacity, _emberAnimated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let PortfolioItemComponent = (_dec = Ember._action, (_class = class PortfolioItemComponent extends _component.default {
    get isBlurred() {
      let blurred = !this.args.project.isFocused;
      return blurred;
    }

    *transition({
      duration,
      receivedSprites,
      insertedSprites,
      removedSprites
    }) {
      // console.log("index: ", arguments[0]);
      receivedSprites.forEach(sprite => {
        sprite.applyStyles({
          'z-index': '1'
        }); //      resize(sprite);
        //      move(sprite);
      });
      removedSprites.forEach(sprite => {
        (0, _opacity.fadeOut)(sprite, {
          duration: duration / 2
        });
      });
      yield (0, _emberAnimated.wait)(duration / 2);
      insertedSprites.forEach(sprite => {
        (0, _opacity.fadeIn)(sprite, {
          duration: duration / 2
        });
      });
    }

    focus(project) {
      project.isFocused = true;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "focus", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "focus"), _class.prototype)), _class));
  _exports.default = PortfolioItemComponent;
});