define("m-corp/routes/care/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CareController extends Ember.Controller {// 1) They want a drop down menu from TYPE OF SERVICE CONTACT field (Note: Delete ‘CONTACT’)
    // 3 drop downs to be:
    // Service Call
    // Year End Visit
    // Other
    // 2) They want a drop down menu from PROJECT NAME field
    // 2 drop downs to be:
    // Triomphe
    // Étoile
  }

  _exports.default = CareController;
});