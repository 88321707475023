define("m-corp/routes/components/care-hero/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GkvKD+VX",
    "block": "{\"symbols\":[\"@alt\",\"@src\",\"@animationId\"],\"statements\":[[6,[37,0],[[32,3]],[[\"initialInsertion\",\"finalRemoval\",\"duration\",\"use\"],[true,true,1000,[32,0,[\"imageTransition\"]]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"img\"],[14,0,\"w-full h-full\"],[15,\"alt\",[32,1]],[15,\"src\",[32,2]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"animated-value\"]}",
    "meta": {
      "moduleName": "m-corp/routes/components/care-hero/template.hbs"
    }
  });

  _exports.default = _default;
});