define("m-corp/routes/portfolio/project/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PortfolioProjectRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = (_temp = class PortfolioProjectRoute extends Ember.Route {
    constructor() {
      super(...arguments); // this.router.on('routeWillChange', (transition) => {
      //   this.manageAnimatedOrphansZindex(transition);
      // });

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "appState", _descriptor2, this);
    }

    manageAnimatedOrphansZindex(transition) {
      const orphans = document.getElementById('orphans');

      if (transition.from && transition.from.name.startsWith('portfolio.') && transition.to.name === 'portfolio.project') {
        orphans.classList.replace('z-30', '-z-10');
        this.resetOrphansLater('-z-10');
      }

      if (transition.from && transition.from.name === 'portfolio.project' && transition.to.name.startsWith('portfolio.')) {
        orphans.classList.replace('z-30', 'z-40');
        this.resetOrphansLater('z-40');
      }
    }

    resetOrphansLater(from) {
      Ember.run.later(this, function () {
        // console.log('resetting orphans');
        const orphans = document.getElementById('orphans');
        orphans.classList.replace(from, 'z-30');
      }, 50200);
    }

    model(params) {
      let model = this.modelFor('portfolio').findBy('id', params.project_id);
      this.appState.setupMenuForProject(model);
      return model;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "appState", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PortfolioProjectRoute;
});