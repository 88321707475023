define("m-corp/routes/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xO/Z3hyp",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"flex flex-col relative items-center\\n            w-screen h-full\"],[12],[2,\"\\n\\n  \"],[8,\"site-menu\",[],[[\"@currentRouteName\"],[[32,0,[\"router\",\"currentRouteName\"]]]],null],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex flex-col w-full justify-center max-w-page bg-white shadow pt-18\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,1,\"viewport-stage\"],[14,0,\"flex flex-col h-page w-full max-w-page overflow-scroll\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,1,\"orphans\"],[14,0,\"absolute inset-0 pointer-events-none\"],[12],[8,\"animated-orphans\",[],[[],[]],null],[13],[2,\"\\n      \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"fixed inset-0 z-10 pointer-events-none\"],[12],[2,\"\\n    \"],[8,\"home-ms\",[],[[],[]],null],[2,\"\\n    \"],[8,\"video-bg\",[],[[\"@currentRouteName\"],[[32,0,[\"router\",\"currentRouteName\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[8,\"new-version-notifier\",[],[[\"@updateMessage\"],[\"Outline Graphics has updated this application from version {oldVersion} to {newVersion}.  Please relaod this page to view the current version.\"]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "m-corp/routes/application/template.hbs"
    }
  });

  _exports.default = _default;
});