define("m-corp/router", ["exports", "m-corp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route('portfolio', function () {
      this.route('project', {
        path: '/:project_id'
      });
      this.route('projects', {
        path: '/'
      }, function () {
        this.route('more');
      });
      this.route('featured', {
        path: '/featured'
      });
      this.route('master-planned', {
        path: '/master-planned'
      });
      this.route('hospitality'), {
        path: '/hospitality'
      };
      this.route('commercial'), {
        path: '/commercial'
      };
      this.route('residential'), {
        path: '/residential'
      };
      this.route('restoration'), {
        path: '/restoration'
      };
      this.route('paris');
      this.route('paris-map');
    });
    this.route('project', {
      path: '/:project_id'
    });
    this.route('one');
    this.route('care');
    this.route('contact');
    this.route('careers');
    this.route('about');
  });
});