define("m-corp/routes/components/io/detail-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Fm3cHKPu",
    "block": "{\"symbols\":[\"@project\",\"@stage\",\"@portfolio\"],\"statements\":[[8,\"animated-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"relative flex items-center\\n            mx-6 mb-6\\n            z-10\\n            sm:mx-0\\n            lg:ml-18\\n            lg:mb-18\\n            xl:ml-39\"],[12],[2,\"\\n\\n\"],[6,[37,1],[[30,[36,0],[[32,1,[\"id\"]],\"portfolio\"],null]],[[\"initialInsertion\",\"duration\",\"use\"],[true,1000,[32,0,[\"cardElement\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"img\"],[14,0,\"absolute object-cover w-full h-full opacity-0\"],[15,\"alt\",[32,1,[\"name\"]]],[15,\"src\",[32,3]],[12],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,1,[\"id\"]],\"detail\"],null]],[[\"initialInsertion\",\"duration\",\"use\"],[true,1000,[32,0,[\"detailElement\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"img\"],[14,0,\"\"],[15,\"alt\",[32,1,[\"name\"]]],[15,\"src\",[32,2]],[12],[13],[2,\"\\n\\n  \"],[10,\"img\"],[14,0,\"absolute object-cover w-full h-full\"],[15,\"alt\",[32,1,[\"name\"]]],[15,\"src\",[32,2]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"animated-value\"]}",
    "meta": {
      "moduleName": "m-corp/routes/components/io/detail-image/template.hbs"
    }
  });

  _exports.default = _default;
});