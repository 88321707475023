define("m-corp/models/photo", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PhotoModel extends _model.default {}

  _exports.default = PhotoModel;
});