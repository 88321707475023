define("m-corp/routes/components/page-title/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rD4SwAcu",
    "block": "{\"symbols\":[\"line\",\"line\",\"&attrs\",\"@lines\"],\"statements\":[[8,\"animated-container\",[[24,0,\"lg:w-full\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],null,[[\"initialInsertion\",\"finalRemoval\",\"duration\",\"use\"],[true,true,1000,[32,0,[\"copyTransition\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n\\n\"],[11,\"div\"],[17,3],[24,0,\"hidden sm:block text-3xl sm:text-3xl lg:text-4xl leading-none uppercase font-poynter tracking-normal\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h1\"],[14,0,\"inline-block gold-title\"],[12],[10,\"span\"],[12],[1,[32,2]],[13],[13],[10,\"br\"],[12],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[13],[2,\"\\n\"],[11,\"div\"],[17,3],[24,0,\"sm:hidden text-3xl sm:text-3xl lg:text-4xl leading-none uppercase font-poynter tracking-normal\"],[12],[2,\"\\n    \"],[10,\"h1\"],[14,0,\"inline-block gold-title-sm\"],[12],[10,\"span\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[13],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"animated-value\"]}",
    "meta": {
      "moduleName": "m-corp/routes/components/page-title/template.hbs"
    }
  });

  _exports.default = _default;
});