define("m-corp/routes/components/io/hero-headline/component", ["exports", "@glimmer/component", "ember-animated/motions/opacity", "ember-animated"], function (_exports, _component, _opacity, _emberAnimated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IoHeroHeadlineComponent extends _component.default {
    *transition({
      duration,
      insertedSprites,
      removedSprites
    }) {
      yield (0, _emberAnimated.wait)(duration * .75);
      insertedSprites.forEach(sprite => {
        (0, _opacity.fadeIn)(sprite, {
          duration: duration * .25
        });
      });
    }

  }

  _exports.default = IoHeroHeadlineComponent;
});